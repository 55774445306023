export const transEn = {
	"Account": "Account",
	"Add": "Add",
	"AddModule": "Add module",
	"AddProperty": "New property",
	"AddPropertyVersion": "New property version",
	"AddPortfolio": "New portfolio",
	"AddToFavorites": "Add to favorites",
	"AdjustedIncrement": "Adjusted Increment",
	"AdvancePayment": "Advance payment",
	"AdvancePaymentShort": "Adv.",
	"Advanced": "Advanced",
	"AllVersions": "All versions",
	"Alternative": "Alternative",
	"Always": "Always",
	"AmortizationInformation": "Amortization information",
	"AmortizationType": "Amortization type",
	"Amount": "Amount",
	"Area": "Area",
	"Area2": "Area",
	"AreaAmount": "Amount",
	"AreaDistributionGraph": "Area distribution",
	"AreaDistributionVacancyGraph": "Area distribution (Vacancy)",
	"Areas": "Areas",
	"AvailableDataTables": "Available data tables",
	"AvailableFields": "Available fields",
	"AvailableTables": "Available tables",
	"AvailableTypes": "Available types",
	"BaseData": "Basedata",
	"BaseNumber": "Base no",
	"BaseRent": "Base rent",
	"BaseTax": "Base tax",
	"BaseValues": "Base values",
	"Basemonth": "Base month",
	"Benefit": "Benefit",
	"BuildingDevRights": "Buildning dev. rights",
	"Burden": "Burden",
	"CI": "CI",
	"Calculate": "Calculate",
	"Calculation": "Calculation",
	"CalculationEnd": "Calculation end",
	"CalculationEndShort": "CE",
	"CalculationError": "Calculation failed. Amend version",
	"CalculationInterest": "Calculation interest",
	"CalculationPeriod": "Calculation period",
	"CalculationPeriods": "Calculation periods",
	"CalculationPortfolio": "Calculation portfolio",
	"CalculationStart": "Calculation start",
	"CalculationVersion": "Calculation version",
	"Calculations": "Calculations",
	"Cancel": "Cancel",
	"CapitalExpenditure": "Capital Expenditure",
	"CarePremises": "Care premises",
	"CashFlow": "Cashflow",
	"CashFlowAnalysis": "Cash flow analysis",
	"Change": "Change",
	"ChangeFirstIncrement": "Change first increment",
	"ChangePortfolio": "Change portfolio",
	"ChangePosition": "Change position",
	"ChangeSortOrder": "Change sort order",
	"ChangeVersion": "Change version",
	"ClassificationHeader": "Location, standard and condition",
	"Clause": "Clause",
	"Clear": "Clear",
	"ClickToEditPicture": "Click to edit picture",
	"Close": "Close",
	"CollapseAll": "Collapse All",
	"Collection": "Collection",
	"ColumnBreak": "Column break",
	"ColumnBreakContinuous": "Column break (continuous)",
	"ColumnDefinition1": "Column definition - one column",
	"ColumnDefinition2": "Column definition - two columns",
	"Comment": "Comment",
	"InternalComment": "Internal comment",
	"CommonInstallation": "Community installation",
	"CommunityAssociation": "Community Association",
	"Condition": "Condition",
	"Confirm": "Confirm",
	"ConfirmAmortizationTypeChange": "Changing type will clear the amortization list",
	"ConfirmCopyPortfolio": "The portfolio '{0}' will be copied, do you want to continue?",
	"ConfirmDeleteGroup": "Do you want to delete the user group?",
	"ConfirmDeleteImage": "Do you want to delete the image?",
	"ConfirmDeleteModule": "Do you want to delete the module?",
	"ConfirmDeletePropertyVersion": "Do you want to delete property version: ",
	"ConfirmDeleteRow": "Do you want to delete the row?",
	"ConfirmDeleteRowTable": "Do you want to delete the row?",
	"ConfirmDeleteUserDefArea": "Do you want to remove the area?",
	"ConfirmDiscardChanges": "Discard changes?",
	"ConfirmExitYieldUpdate": "Do you want to update interest rates for both stream- and rest value?",
	"ConfirmImportContracts": "The selected file will be imported, do you want to continue?",
	"ConfirmImportPortfolio": "The selected file will be imported, do you want to continue?",
	"ConfirmLockPortfolioVersions": "This action will lock all versions in the portfolio. Continue?",
	"ConfirmPastePortfolioVersions": "All versions from '{0}' will be copied to portfolio {1}. Continue?",
	"ConfirmPrognosisParameterDelete": "All related prognoses will be removed. Continue?",
	"ConfirmPropertyDataImport": "Importing real estate data is a payed service. Do you want to continue?",
	"ConfirmPropertyDetailsLink": "This operation replaces existing property details with imported data. Do you want to continue?",
	"ConfirmPropertyDetailsUnlink": "Do you want to unlink the connection to imported data? No data will be lost.",
	"ConfirmPropertyPositionChange": "Do you want to update the property ({property}) position?",
	"ConfirmRemoveContracts": "Do you want to remove selected contract(s)?",
	"ConfirmRemoveCostIncomes": "Do you want to remove selected cost income(s)?",
	"ConfirmRemoveItem": "Do you want to remove '{0}'?",
	"ConfirmRemoveProperty": "Do you want to remove the property '{0}'?",
	"ConfirmReplaceFile": "File already exists. Replace?",
	"ConfirmSetVacancyFields": "Remove all addons and set fields as vacant?",
	"ConfirmSortOrderChange": "Do you want to change the sort order for the current row?",
	"ConfirmUndoChanges": "Do you want to undo changes?",
	"ConfirmUnlockPortfolioVersions": "This action will unlock all versions in the portfolio. Continue?",
	"ConfirmUpdateFirstIncrementByPortfolio": "Update first increment dates for all contracts?",
	"ConfirmUpdateFirstIncrementByVersions": "Update first increment dates for all versions?",
	"ConfirmUpdateSelectedContracts": "Continue and update selected contracts?",
	"ConfirmUpdateSelectedCostIncomes": "Continue and update selected C/I-posts?",
	"ConfirmUpdateSelectedProperties": "Do you want to update the version's date?",
	"ConfirmValuationInstance": "You have unsaved changed, do you want to save?",
	"ConfirmValuationModule": "You have unsaved changed, do you want to save?",
	"ConstructionPlan": "Construction plan",
	"ConstructionPlanTypeList_Conditionsplan": "Conditions plan",
	"ConstructionPlanTypeList_ConstructionPlan": "Construction plan",
	"ConstructionPlanTypeList_DetailPlan": "Detail plan",
	"ConstructionYear": "Construction year",
	"Content": "Content",
	"Contract": "Contract",
	"ContractEnd": "Contract end",
	"ContractImportAddedMessage": "The file has been added to the import queue",
	"ContractImportError": "An error occurred during contract import",
	"ContractPeriod": "Contract period",
	"ContractStart": "Contract start",
	"ContractView_List_Addition": "Amd.",
	"ContractView_List_BaseRent": "Base rent",
	"ContractView_List_CalculationEnd": "E. end",
	"ContractView_List_PaymentType": "Pay. type",
	"ContractView_List_PremisesId": "Prem. Id",
	"ContractView_List_PremisesType": "Premises type",
	"ContractView_TabContract_CancelAtLastStreamEnd": "Canc. at last str. end",
	"ContractView_TabContract_FirstStream": "Values for 1st payment flow",
	"ContractView_TabContract_UseMarketRent": "Use marketrent",
	"ContractedAssessedRentGraph": "Contracted and assessed rent",
	"ConversionFactor": "Conv. factor",
	"Copy": "Copy",
	"CopyHasBeenCreatedOf": "A copy has been created of: ",
	"CopyPortfolio": "Copy portfolio",
	"CopyPropertyVersion": "Copy property version",
	"CopyToOtherProperty": "Copy to other property",
	"CopyVersions": "Copy versions",
	"CorporateIdentityNumber_Short": "Corp. id. no.",
	"Correction": "Correction",
	"CostIncome": "C/I-post",
	"CostIncomes": "C/I-posts",
	"CostIncomeShortcutInvestments": "Investments",
	"CostIncomeShortcutTenantImprovement": "TI-adaptions",
	"CostIncomeShortcutLeaseholdFee": "Leasehold fee",
	"Costs": "Costs",
	"Count": "Count",
	"Counties": "Municipalities",
	"Countries": "Countries",
	"Country": "Country",
	"County": "Municipality",
	"Create": "Create",
	"CreateValuation": "Create valuation",
	"CreateValuationReport": "Create Valuation",
	"Created": "Created",
	"Currencies": "Currencies",
	"Customer": "Customer",
	"Currency": "Currency",
	"Danish": "Danish",
	"DataTag": "Data: ",
	"Database": "Database",
	"Date": "Date",
	"DayOfLetting": "Day of letting",
	"DeductingExpenses": "Deducting expenses",
	"Delete": "Delete",
	"DeleteDependency": "Delete dependency",
	"DeleteImage": "Delete image",
	"DeleteModule": "Delete module",
	"DeleteSelected": "Delete selected",
	"Denotation": "Denotation",
	"Description": "Description",
	"DetailedDevPlan": "Detailed dev. plan",
	"DetailedStreams": "Detailed streams",
	"Details": "Details",
	"Development": "Development",
	"DevelopmentNotInVersion": "The development type is not active in the current property version.",
	"DevelopmentType": "Development type",
	"DevelopmentTypes": "Development types",
	"DevelopmentTypesInVersion": "Development types in version",
	"DiscretionaryHyphen": "Discretionary hyphen",
	"Display most recent": "Display most recent",
	"DisplayInIndex": "Display in index",
	"DisplayModule": "Display module",
	"DisplayMoreSettings": "Show more",
	"DownloadTrattTemplate": "Download import template",
	"DuplicateContract": "Duplicate contract",
	"E-mail": "E-mail",
	"Easement": "Easement",
	"Edit": "Edit",
	"EditCopyMessage": "Editing copy of ",
	"EditFirstIncrement": "Edit first increment date",
	"EditIncrementDateByPortfolio": "Edit first increment date for versions",
	"EditLoan": "Edit loan",
	"EditProfilePicture": "Edit profile picture",
	"EditPropertyVersion": "Edit property version",
	"EditSelectedContracts": "Edit selected contracts",
	"EditContractsMarketRent": "Change market rents",
	"EditSelectedCostIncomes": "Edit selected cost incomes",
	"EditSelectedPropertyVersions": "Edit property versions",
	"EditUser": "Edit user",
	"EditingNewProperty": "Editing new portfolio",
	"Employer": "Employer",
	"End": "End",
	"EndDate": "End date",
	"EndOfStream": "Stream end",
	"English": "English",
	"EnterAreaName": "Enter area name",
	"EnterPortfolio": "Enter portfolio name",
	"EnterSearchText": "Enter search text...",
	"Equity": "Equity",
	"EquityAtCalculationEnd": "Equity at calculation end",
	"EquityAtCalculationStart": "Equity at calculation start",
	"Error": "Error",
	"ErrorActionFailed": "The action could not be completed",
	"ErrorMessage_InterestRateInvalidDates": "Date must be less than or equal to the start date of the loan",
	"ErrorMessage_InterestRatesMissing": "At least one interest rate must exist",
	"ErrorMessage_NoCalculationsFound": "Version is not yet calculated",
	"ErrorMessage_NoInvestmentCalcFound": "To create an investment calculation, you must specify a purchase price",
	"ErrorMessage_RecoveryEditInvalidContract": "The recovery can't be opened/edited because the contract has validation errors.",
	"ErrorMessage_VersionLocked": "The property version is locked and can not be changed.",
	"ErrorUnexpected": "Unexpected error",
	"EstimatedMarketValue": "Estimated market value {currency}",
	"Examiner": "Examiner",
	"ExcludeSubsidies": "Exclude subsidies",
	"ExcludeTaxes": "Exclude taxes",
	"NewIdentifier": "New version identifier",
	"UseVersionIdentifiersFromImport": "Use version identifiers from import",
	"ExitYield": "Exit yield",
	"ExitYieldPercentage": "Exit yield (%)",
	"ExpandAll": "Expand all",
	"Export": "Export",
	"ExportPortfolio": "Export portfolio",
	"ExportShort": "Export",
	"ExportToCurrentProperty": "Export to current property",
	"Download": "Download",
	"Favorites": "Favorites",
	"File": "File",
	"Filter": "Filter",
	"FilterName": "Filter by name",
	"FilterEmail": "Filter by e-mail",
	"Finish": "Finnish",
	"FirstIncrementContracts": "First increment (contracts)",
	"FirstIncrementMonth": "1. incr.",
	"FirstIncrementMonth_Long": "First increment",
	"FirstIncrementRecoveries": "First increment (recoveries)",
	"FixedAdjustment": "Fixed adjustment",
	"FixedAmortization": "Fixed amortization",
	"FixedTo": "Fixed to",
	"FloorPlan": "Floor plan",
	"Flow": "Flow",
	"Frequency": "Frequency",
	"FrequencyAmortizement": "Freq. amortizement",
	"FrequencyInterest": "Freq. interest",
	"From": "From",
	"FromArea": "From area",
	"FromProperty": "From property",
	"FromVersion": "From version",
	"FrontPage": "Front page",
	"Frontpage": "Frontpage",
	"FrontpageNoSummary": "Frontpage w/o sunmmary",
	"Function": "Function",
	"Funnel": "Funnel",
	"FutureEconomicVacancyGraph": "Future economic vacancy",
	"Garage": "Garage",
	"General": "General",
	"GenerateReport": "Generate report",
	"GenerateReports": "Generate reports",
	"GeneratedFiles": "Generated files",
	"Get": "Get",
	"GetLandLease": "Get land lease",
	"GetPropertyVersion": "Get property version",
	"Graph": "Graph",
	"Graphs": "Graphs",
	"GroundLease": "Ground lease",
	"Group": "Group",
	"Groups": "Groups",
	"HasArea": "Has Area",
	"HeaderOne": "Header 1",
	"HeaderText": "Header text",
	"HeaderThree": "Header 3",
	"HeaderTwo": "Header 2",
	"HideGridLines": "Hide grid lines",
	"High": "High",
	"Housing": "Housing",
	"HttpRequestErrorMessage": "An error occurred during the remote call. Message: {message}. Data: {data}.",
	"HttpRequestErrorTitle": "Remote call error",
	"IPD": "IPD",
	"IPD6": "IPD 6",
	"IPDNumber": "IPD number",
	"IPDPropertyVersionDialogTitle": "IPD - Property version",
	"IPDStatus": "IPD Status",
	"Identifier": "Identifier",
	"IfAllDataExist": "If all data exist",
	"IfDataExist": "If data exist",
	"IfPrelAssessment": "If prel. assessment",
	"IfPreviousModuleWritten": "If previous module written",
	"IfPreviousModuleNotWritten": "If previous module not written",
	"ImageUploadError": "The image file could not be uploaded",
	"Images": "Images",
	"Import": "Import",
	"ImportContracts": "Import contracts",
	"ImportExport": "Import & Export",
	"ImportPortfolio": "Import portfolio",
	"Import_Link": "Import / link",
	"InDataFile": "In data file",
	"Inactive": "Inactive",
	"IncludeAdjustmentColumn": "Rents, adj. column",
	"IncludeGreenDeal": "Green deals column",
	"IncludeForumLogo": "Include forum logo",
	"IncludeFrontpage": "Include frontpage",
	"IncludeLoan": "Include loan",
	"IncludeModules": "Include modules",
	"IncludeTerminationProlongingColumn": "Term. & prol. column",
	"IncludeInternalComments": "Internal Comments",
	"IncludeRecoveryAdditionColumn": "Rec. addition conv. column",
	"Index": "Index",
	"IndexAppendix": "Table of contents",
	"IndexClause": "Index Clause",
	"IndexClauses": "Index clauses",
	"IndexDenotation": "Index denotation",
	"IndexDenotations": "Index denotations",
	"IndexName": "Index name",
	"IndexPercentage": "Index %",
	"IndexValue": "Index value",
	"IndexValues": "Index values",
	"Industry": "Industry",
	"Inflation": "Inflation %",
	"Insert": "Insert",
	"InspectionDate": "Inspection date",
	"Instalment": "Instalment",
	"InstalmentTimeYear": "Inst. time (year)",
	"InterestInformation": "Interest information",
	"InterestRatePercentage": "Interest rate (%)",
	"InterestType": "Interest type",
	"Interests": "Interests",
	"Interior1": "Interior 1",
	"Interior2": "Interior 2",
	"Interval": "Interval",
	"InvestmentCalculation": "Investment calculation",
	"InvolvedProperties": "Involved properties",
	"Ipd4": "IPD 4",
	"IpdSettings": "IPD settings",
	"IrrDistribution": "IRR Distribution",
	"IrrEquity": "IRR levered",
	"IrrTotal": "IRR unlevered",
	"JobReady": "{type} '{report}' is now ready",
	"JobStatus_Canceled": "Canceled",
	"JobStatus_Failed": "Failed",
	"JobStatus_Finished": "Finished",
	"JobStatus_InProgress": "In progress",
	"JobStatus_Pending": "Pending",
	"JobType_Calculation": "Calculation (version)",
	"JobType_CalculationDetailed": "Detaliled calculation",
	"JobType_CalculationPortfolio": "Calculation (portfolio)",
	"JobType_ContractImport": "Contract import",
	"JobType_CopyPortfolio": "Portfolio copy",
	"JobType_ExportPortfolio": "Portfolio export",
	"JobType_ImportPortfolio": "Portfolio import",
	"JobType_Report": "Report",
	"JointOwnershipProperties": "Jointownership (properties)",
	"KeyRatios": "Key ratios",
	"KeyRatiosCompact": "Key ratios compact",
	"LandLease": "Land lease",
	"LandLease_GroundLease": "Land lease/Ground lease",
	"Language": "Language",
	"Latest": "Latest",
	"LatestVersion": "Latest version",
	"LeaseArea": "Lease area",
	"LeaseAreaPrimaryPremisesError": "Atleast one lease are has to be of main property type",
	"LettedTo": "Letted to",
	"Link": "Link",
	"LinkMap": "Link map",
	"Linked": "Linked",
	"LinkedDate": "Linked date",
	"Loan": "Loan",
	"LoanAmount": "Loan amount",
	"LoanAtCalculationEnd": "Loan at calculation end",
	"LoanAtCalculationStart": "Loan at calculation start",
	"LoanName": "Loan name",
	"Location": "Location",
	"LockVersions": "Lock versions",
	"Locked": "Locked",
	"LongCalculationInfo": "Optimizing calculations",
	"M2": "m²",
	"MainPremisesType": "Main premises type",
	"ManageLoan": "Manage loans",
	"Map": "Map",
	"MapWithBorder": "Map with border",
	"MapSavedErrorNotification": "An error occurred during the image export. Error message: {err} ",
	"MapSavedSuccessNotification": "Image saved to the property '{property}'",
	"Margin": "Margin",
	"MarginPercentage": "Margin (%)",
	"MarketRent": "Market Rent",
	"MarketRentIncl": "Market Rent incl recoveries",
	"MarketRents": "Market Rents'",
	"MarketRentDate": "M. rent date",
	"MarketRentDate2": "Market rent date",
	"MarketRentShort": "M. rent",
	"MarketRentM2": "Market rent per m²",
	"MarketSituation": "Market situation",
	"MarketType": "Market type",
	"MarketTypes": "Market types",
	"MarketValue": "Market value",
	"MaxInvestmentsColumns": "Max columns - Investments",
	"MaxNoiColumns": "Max columns - NOI",
	"MergeAreaColumns": "Merge area columns",
	"Message_PropertyDetailsLinked": "The property is linked to external provider",
	"Modern": "Modern",
	"Module": "Module",
	"ModuleDependency": "Module dependency",
	"RemoveWarningModuleDependency": "You have to remove the dependency on module '{0}'",
	"ModuleHasBeenDeleted": "The module has been deleted",
	"ModuleHeader": "Module header",
	"ModuleText": "Module text",
	"Modules": "Modules",
	"Month": "Month",
	"MonthOfChange": "Month of change",
	"MonthOfChangeShort": "Mon.",
	"MoreSearchResults": "The search resulted in more than {max} results. Please refine your query.",
	"MovingInDate": "Moving in date",
	"MRICS": "MRICS",
	"MRICStext": "MRICS text",
	"Municipality": "Municipality",
	"MyProfile": "My profile",
	"Name": "Name",
	"Name2": "Name",
	"New": "New",
	"NewImport": "New import",
	"No": "No",
	"NoMarketType": "(No market type)",
	"NoOfDecimals": "# dec.",
	"NoOfProlongingMonths_Short": "No of prol. mon.",
	"NoOfUnits": "# uni.",
	"NoPortfolioSelected": "No portfolio is selected",
	"NoPropertySelected": "No property is selected",
	"NoVersionSelected": "No property version is selected",
	"NonBreakingSpace": "Non breaking space",
	"None": "None",
	"Normal": "Normal",
	"NotDefined": "Not defined",
	"NotLinked": "Not linked",
	"NotReady": "Not ready",
	"NumberOf": "Number of",
	"NumberOfContracts": "Contracts: ",
	"NumberOfContractsGraph": "Number of contracts",
	"OK": "OK",
	"ObjectIdentifier": "Contract no",
	"Office": "Office",
	"Offices": "Offices",
	"Okay": "Okay",
	"OnlyContracts": "Only contracts",
	"OnlyUp": "Up only",
	"OperationGraph": "Operation",
	"OperationNetGraph": "Operation net",
	"Operations": "Operations",
	"OrderNo": "Order no",
	"OtherAssumptions": "Other assumptions",
	"Outdated": 'Warning, this calculation is outdated',
	"OutdatedShort": 'Outdated',
	"PageBreakAfterModule": "Page break after module",
	"PageBreakBeforeModule": "Page break before module",
	"PageFooter": "Footer",
	"PageHeader2": "Page header",
	"Paragraph": "Paragraph",
	"Parking": "Parking",
	"PassingRent": "Passing Rent",
	"PassingRentEx": "Passing rent ex. recoveries",
	"PassingRentShort": "P. rent",
	"PassingRentM2": "Passing rent per m²",
	"PassingRentExRecM2": "Passing rent ex. recoveries m²",
	"Paste": "Paste",
	"PasteVersions": "Paste versions",
	"PaymentFlowType": "Payment flow type",
	"PaymentFlows": "Payment flows",
	"PaymentFrequencyShort": "F",
	"PaymentGroup": "Payment group",
	"PaymentGroup/PaymentType": "Payment group / Payment type",
	"PaymentGroupDivision": "Payment group division",
	"PaymentGroups": "Payment groups",
	"PaymentType": "Type of payment",
	"PaymentTypes": "Payment types",
	"Percentage": "Percentage",
	"PercentageAdjustment": "Percentage adjustment",
	"PercentageGraph": "Percentage of rental expenses",
	"Percentages": "Percentages",
	"PercentualAmortization": "Percentual amortization",
	"Period": "Period",
	"Phone": "Phone",
	"Photo": "Photo",
	"PhotoTag": "Photo: ",
	"Photos": "Photos",
	"Pick": "Pick",
	"PickAction": "Pick action",
	"PickDocument": "Choose document",
	"PinSizeScale": "Pin size scale",
	"Portfolio": "Portfolio",
	"PortfolioCopyAddedMessage": "The portfolio copy operation has been added to the queue",
	"PortfolioImportAddedMessage": "The file has been added to the portfolio import queue",
	"PortfolioImportError": "An error occurred during portfolio import",
	"PortfolioName": "Portfolio name",
	"PortfolioValuation_PreliminaryAssessment": "under specific conditions",
	"PortfolioValuations": "Portfolio Valuation",
	"PortfolioVersionsCopyPrepared": "Versions added to clipboard, click 'Paste versions' to copy them to another portfolio",
	"Portfolios": "Portfolios",
	"PortfoliosView_SearchHeading": "Search and add property versions",
	"PreliminaryAssessment": "Preliminary Assessment",
	"PremisesGroupName": "Group name",
	"PremisesGroupType": "Type",
	"PremisesGroups": "Premises Groups",
	"PremisesType": "Premises type",
	"PremisesTypes": "Premises type",
	"PresentValue": "Present Value",
	"PresentValueSum": "Sum Present Value",
	"PresentEquity": "Equity",
	"PresentValueCashFlow": "Present Cashflow Value",
	"PresentValueRestValue": "Present Restvalue",
	"PricePerM2": "Price / m²",
	"Priority": "Priority",
	"ProfitRatio": "Profit ratio",
	"Prognosis": "Prognosis",
	"PrognosisAlternative": "Prognosis alternative",
	"PrognosisAlternatives": "Prognosis alternatives",
	"PrognosisParameter": "Prognosis parameter",
	"PrognosisParameterType": "Prognosis parameter type",
	"PrognosisParameterTypes": "Prognosis parameter types",
	"PrognosisParameters": "Prognosis parameters",
	"Prolonging": "Prolonging",
	"ProlongingType": "Prolonging Type",
	"Properties": "Properties",
	"Property": "Property",
	"PropertyAddressList": "Property address list",
	"PropertyDetails_text1": "In the report, this text will be ended by 'The property has an area of ",
	"PropertyDetails_text2": "The property is a land lease",
	"PropertyIdentifier": "Property identifier",
	"PropertyName": "Property name",
	"PropertyOwnerRepr": "Property owner repr.",
	"PropertyTax": "Property tax",
	"PropertyTaxType": "Property tax type",
	"PropertyTaxTypes": "Property tax types",
	"PropertyVersion": "Property version",
	"PropertyVersionDetails": "Property version - details",
	"PropertyVersions": "Property versions",
	"PropertyVersionsAreLocked": "Cannot calculate, Some property version(s) are locked.",
	"Provider": "Provider",
	"ProviderPropertyId": "Providers property id",
	"ProviderPropertyName": "Providers property name",
	"Public": 'Public',
	"PurchasePrice": "Purchase price",
	"Purpose": "Purpose",
	"QueueNo": "Queue no",
	"Rate": "Rate",
	"Ready": "Ready",
	"RealInterest": "Real interest",
	"RebuiltYearSpecial": "Rebuilt (year, year, ...)",
	"Recoveries": "Recoveries",
	"Recovery": "Recovery",
	"RecoveryPerM2": "Recoveries per m²",
	"RecoveryShortcutTypesHeat": "Heat",
	"RecoveryShortcutTypesOther": "Other",
	"RecoveryShortcutTypesTax": "Tax recovery (share of)",
	"RecoveryAdditionForConversion": "Add. conv",
	"RecoveryAdditionForConversionShort": "conv.",
	"RecoveryTurnoverShort": "turn.",
	"RecoveryRentDiscounts": "Rent discounts",
	"Region": "Region",
	"Regions": "Regions",
	"RegisteredOwner": "Registered owner",
	"Remaining": "Remaining",
	"Rent5Year": "Rent 5 year req. %",
	"RentComposition": "Rent composition",
	"RentExRecoveriesShort": "P. ex recov.",
	"RentLevel": "Rent level",
	"RentRebates": "Rent rebates",
	"RentRecoveryShort": "R. Amds",
	"Report": "Report",
	"ReportReady": "The report '{0}' is now ready",
	"ReportTemplate": "Report template",
	"ReportTypeCalculation": "Calculation",
	"ReportTypeContractsToExcel": "Contract list",
	"ReportTypeIpd4": "IPD 4",
	"ReportTypeIpd6": "IPD 6",
	"ReportTypeIpd6NotReady": "IPD6 not ready",
	"ReportTypeKeyRatios": "Key ratios",
	"ReportTypeKeyRatiosShort": "Key ratios short",
	"ReportTypeKeyRatiosExtended": "Key ratios (extended)",
	"ReportTypeSensitivityAnalysis": 'Sensitivity Analysis',
	"ReportTypePortfolioDetails": "Portfolio details",
	"ReportTypePropertyAddressList": "Property address list",
	"ReportTypeValuation": "Valuation",
	"Reports": "Reports",
	"ResidentialShort": "Resid.",
	"RestValue": "Rest value",
	"RestValueRate": "Rest value rate (%)",
	"RestValueYear": "Restvalue year",
	"Result": "Result",
	"Retrieved": "Retrieved",
	"Rights": "Rights",
	"Rounding": "Rounding",
	"RowDeleteError": "The row could not be deleted. Error: ",
	"RowSaveError": "The row could not be saved. Error: ",
	"Rows": "Rows",
	"Save": "Save",
	"SaveAndClose": "Save and close",
	"SaveAs": "Save as",
	"Search": "Search",
	"SearchEmail": "Search E-mail",
	"SearchLocation": "Search location",
	"SearchModule": "Module search",
	"SearchName": "Search name",
	"SearchPortfolio": "Search portfolio",
	"SearchProperty": "Search property",
	"SearchTemplate": "Search template...",
	"SearchUser": "Search user",
	"SearchUsername": "Search username",
	"Select": "Select",
	"SelectContract": "Select contract",
	"SelectCostIncome": "Select C/I-post",
	"SelectFavorite": "Select a favorite...",
	"SelectPropertyVersion": "Select property version",
	"SelectRecent": "Select a recent...",
	"SelectReport": "Select report",
	"SelectUser": "Select user",
	"Selected": "Selected",
	"SelectedContracts": "Selected contracts",
	"SetDependency": "Set dependency",
	"Settings": "Settings",
	"Settlement": "Settlement",
	"Share": "Share",
	"ShareOf": "Share of",
	"ShareOfPaymentPercentage": "Share %",
	"Shares": "Shares",
	"ShowLatest": "Show latest",
	"ShowVersions": "Show versions",
	"Sign": "Sign",
	"SignOut": "Sign out",
	"SigningDate": "Signing Date",
	"Signature": "Signature",
	"SiteAndLocation": "Site and location",
	"SiteMap": "Site map",
	"SiteOwner": "Site owner",
	"SortByName": "Sort by name",
	"SortByValue": "Sort by value",
	"Standard": "Standard",
	"Start": "Start",
	"StartAmount": "Start amount",
	"StartAmountShort": "St. amount",
	"StartAmountUpdated": "Start amount updated!",
	"StartDate": "Start date",
	"StartInterest": "Start interest",
	"StartTime": "Start time",
	"Status": "Status",
	"StatusFailed": "Failed",
	"StatusFinished": "Finished",
	"StatusStarted": "Started",
	"Statuses": "Statuses",
	"Stream": "Stream",
	"StreamRate": "Stream rate (%)",
	"StreamRateGraph": "Stream rate",
	"Subcounty": "District",
	"Subcounties": "Districts",
	"Swedish": "Swedish",
	"SysAdminPermissionText": "THIS USER HAS FULL PERMISSION ON ALL USER DATABASES",
	"TableOfContents": "Table of contents",
	"TableTag": "Table: ",
	"TaxInfo": "Tax info",
	"TaxRate": "Tax rate",
	"TaxRecoveries": "Tax recov.",
	"TaxRecoveryType": "Tax recovery type",
	"TaxType": "Tax type",
	"TaxTypeCodes": "Tax type codes",
	"TaxValuationUnit": "Tax valuation unit",
	"Tenant": "Tenant",
	"TerminationDate": "Termination date",
	"Title": "Title",
	"TitleEng": "English title",
	"To": "To",
	"ToArea": "To area",
	"ToDatabase": "Target database",
	"ToExcel": "To Excel",
	"ToIPD": "To IPD",
	"ToProperty": "To property",
	"ToVersion": "To version",
	"TotalArea": "Total area",
	"TotalEquity": "Total",
	"TotalIndexValues": "Totalt number of index values",
	"Trading": "Trading",
	"TransactionCosts": "Transaction costs",
	"Tree": "Tree",
	"Type": "Type",
	"TypeCode": "Type code",
	"TypeOfPayment": "Type of payment",
	"TypeOfPaymentEmpty": "No payment types found",
	"TypeOfPaymentFilter": "Payment type filter",
	"TypeOfPaymentGroups": "Payment type groups",
	"TypeOfPaymentVacant": "Type of payment (vacant)",
	"TypeOfPayments": "Type of payments",
	"UnSupportedBrowserWarning": "This browser is not recommended. Please use the latest version of Google Chrome",
	"UnSupportedBrowserError": "VdokEditor is only compatible with Google Chrome",
	"Undo": "Undo",
	"Unit": "Unit",
	"Unknown": "Unknown",
	"UnkownType": "Unkown type",
	"Unlink": "Unlink",
	"UnlockVersions": "Unlock versions",
	"UntilCalculationEnd": "Until calculationend",
	"Update": "Update",
	"UpdateStarttimes": "Update starttimes to:",
	"UpdateVersionsInPortfolio": "Update versions",
	"Updated": "Updated",
	"UpdatedAlt": "Updated",
	"Use": "Use",
	"UseModuleAs": "Use module as",
	"User": "User",
	"UserDefinedAreas": "My areas",
	"Username": "Username",
	"Users": "Users",
	"UtilityEasement": "Utility easement",
	"VAT": "VAT",
	"VAT_Soc": "VAT no./Soc.sec.no",
	"Vacancies": "Vacancies",
	"Vacant": "Vacant",
	"VacantArea": "Vacant area",
	"ValidationContract1": "The first streams start date must be less or equal to the contract start date",
	"ValidationContract2": "The last streams end date must be greater than or equal to the contract end date",
	"ValidationContract3": "The contract start date must be less or equal to the contract end date",
	"ValidationErrorNavigation": "There are validation errors that needs to be fixed",
	"ValidationErrors": "Validation errors",
	"ValidationLoan1": "The loan start date must be less or equal to the loan end date",
	"ValidationMarketValueMinZero": "Purchase price must be greater than 0",
	"ValidationNumeric": "The value has to be numeric",
	"ValidationPositiveZero": "The value must be >= 0",
	"ValidationRange": "The value has to be between {0} and {1}",
	"ValidationRecovery1": "The recovery start date must be less or equal to the recovery end date",
	"ValidationRequired": "Required",
	"ValidationStartDateLessThanEndDate": "The start date must be less than end date",
	"ValidationStream1": "The stream start date must be less or equal to the stream end date",
	"ValidationStream2": "Streams are not allowed to overlap",
	"ValidationStream3": "Date gaps are not allowed between streams",
	"ValidationStream4": "The stream start date must be less or equal to first increment date",
	"ValidationStream5": "There must be at least one variable amount",
	"ValidationStream6": "Duplicate dates are not allowed in variable amounts",
	"ValidationStream7": "One ore more errors in variable amounts",
	"ValidationStream8": "One ore more errors in variable percentages",
	"ValidationStream9": "At least one percentage date must be less or equal to the stream start date",
	"ValidationStream10": "There must be at least one stream",
	"ValidationStream11": "Only the last stream can be set to 'calculation end'",
	"ValidationTaxes1": "A property tax must contain at least one tax valuation unit",
	"ValidationTaxes2": "Either development type or prognosis parameter must be set",
	"ValidationTaxes3": "A tax valuation unit must contain at least one tax rate",
	"ValidationTaxes4": "At least one tax rate must have a start date before or equal to the property calculation start",
	"Validation_leaseAreaUsesOverrideAmount": "The lease area uses the \"Value\" property which overrides this market rent",
	"ValidationWarning": "Warning",
	"Valuated": "Valuated",
	"Valuation": "Valuation",
	"ValuationDate": "Valuation date",
	"ValuationUnit": "Valuation unit",
	"Valuations": "Valuations",
	"Valuator": "Valuator",
	"Value": "Value",
	"ValueBuildRight": "Value build right",
	"ValueYear": "Value year",
	"Valuer": "Valuer",
	"ValuerFirst": "Valuer one",
	"ValuerSecond": "Valuer two",
	"VariableAmount": "Variable amount",
	"VariableAmountStreamAdvancedGUI": "Variable percentage (extended)",
	"Version": "Version",
	"VersionsCount": "Versions",
	"Warehouse": "Warehouse",
	"WarningEditingMultiple": "Warning, you are editing multiple versions",
	"WarningUpdateFirstIncrementByPortfolio": "Warning, you are changing first increment date in multiple versions and its contracts",
	"Warning_onePhotoPerModule": "A module can only contain one photo/graph",
	"WrongFileType": "Wrong file type",
	"WrongFormat": "Wrong format",
	"Year": "Year",
	"YearlyAmount": "Yearly amount",
	"YearlyCost": "Yearly cost",
	"YearlyRevenue_CostForObj": "Yearly revenue/cost for obj",
	"Yes": "Yes",
	"ZipCode": "Zip code",
	"Zoning": "Zoning",
	"existingLoan": "Existing Loan",
	"importFromDetails": "Import from property",
	"publicUserDefinedAreas": "Areas",
	"ExcelReport": "Report - Excel",
	"OptimizedCalculation": "Optimized Calculation - Beta",
	"Calculated": "Calculated",
	"LoanDetails": "Loan details",
	"PropertyTemplate": "Property Template",
	"UseAsPropertyTemplate": "Use as template",
	"Address": "Address",
	"EditVersionStartEnd": "Edit version start and end",
	"EditCalculationPeriods": "Edit calculation periods",
	"EditMarketRentStart": "Edit market rent start",
	"EditCostIncomesFirstIncrement": "Edit C/I-posts first increment",
	"ConfirmUpdateSelectedPropertiesCalculationPeriods": "Do you want to update the version's calculation period?",
	"ConfirmUpdateSelectedPropertiesCostIncomesFirstIncrement": "Do you want to update the version's C/I-posts first increment?",
	"ConfirmUpdateSelectedPropertiesMarketRentStart": "Do you want to update the version's start date for market rent?",
	"Permission": "Permission",
	"Permissions": "Permissions",
	"PermissionGroup": "Permission group",
	"AppRight_2": "Import/Export",
	"AppRight_3": "VDok",
	"AppRight_4": "VDok Editor",
	"AppRight_6": "Fia",
	"AppRight_14": "Map",
	"AppRight_15": "Copy portfolio to",
	"AppRight_16": "Prognosis",
	"AppRight_17": "Metria integration",
	"AppRight_18": "Change data",
	"AppRight_19": "Change data",
	"AppRight_20": "Read master data",
	"AppRight_21": "Change master data",
	"NotSpecified": "Not specified",
	"Email": "E-mail",
	"ProfilePicture": "Profile picture",
	"AnUnexpectedErrorOccurred": "An unexpected error occurred",
	"YieldYear1": "Yield current year, %",
	"YieldYear1Percentage": "Yield current year (%)",
	"InitialYield":"Initial yield, %",
	"NormalizedYield":"Net equivalent yield, %",
	"VTB":"Value per assessed tax base",
	"GrossIncomeMultiplier":"Gross Income Multiplier",
	"MarketValueSqmCurrency": "Market Value, {currency}",
	"MarketValueM2": "Market value per m²",
	"InPortfolio": "In portfolio",
	"ErrorMessage_RecoveryMultipleAdd": "The contract must be saved before you can add another recovery.",
	"SearchContract": "Search contract",
	"DiscountRate": "Discount rate",
	"DuplicateMarketRents": "There are multiple rents with the same type ({types}) in the list.",
	"Multiple": "Multiple",
	"UsedByCount": "Used by (count)",
	"NewValue": "New value",
	"Current": "Current",
	"MarketRentsUpdated": "Market rents updated",
	"PasteErrorMultipleColumns": "You can only paste a single column",
	"CompareWithOtherPortfolio": "Compare with other portfolio",
	"Discount": "Discount",
	"TaxWithdrawal": "Tax withdrawal",
	"RecoveriesUpdated": "Recoveries updated",
	"ValueChange": "Change",
	"AdjustRecoveries": "Adjust recoveries",
	"Error_MultiplePaymentStreams": "The recovery contains multiple payment streams and will not be updated",
	"Error_MultipleLeaseAreas": "The contract contains multiple lease areas",
	"Error_StreamUsesDevelopment": "The stream uses development instead of prognosis",
	"Error_IndexStreams": "The recovery contains one or more index streams and will not be updated",
	"Warning_MultipleRecoveries": "There are multiple recoveries of this type. Excess recoveries will be deleted",
	"Warning_WillBeRemoved": "The recovery will be deleted",
	"FirstIncrementNewRecoveries": "New recoveries first increment",
	"ShowDayInReports": "Show day in reports",
	"MiscRecovery": "Misc. rec",
	"Misc": "Other",
	"HeatingAndCooling": "Heating & cooling",
	"MarketRentNotInUse": "Market rent not in use",
	"GrossArea": "Gross area",
	"Warning_OutOfMarketTypes": "Could not create new market rent, there are no market types left.",
	"SaveAndCalculate": "Save & calculate",
	"BadRequest_CalculationRequired": "To use this feature, you must first run a calculation.",
	"BadRequest_RecalculationRequired": "Calculation data is outdated. Perform a new calculation and try again.",
	"CalculationRequired": "Calculation data is outdated. Perform a new calculation and try again.",
	"Warning_VacancyStreamStartLessThanCalcStart": "There are CI entries with payment stream type 'Share of' that has a start time earlier than the calculation start",
	"Error_InactivateMarketType": "The market type is used as a default value for one or more premises types.",
	"CalculatedDatas": "Calculated data",
	"InitialVacancy": "Initial Vacancy",
	"EditAll": "Edit all",
	"EditContracts": "Edit contracts",
	"ComputedColumn": "Computed Column",
	"NotFound": "Not found",
	"MoveStreamDates": "Move all stream dates * months",
	"ClearInternalComments": "Clear internal comments",
	"WarningUnsavedChanges": "There are unsaved changes",
	"ContractBulkEditValidationWarning": "The contract must have exactly one lease area",
	"ContractBulkEditValidationWarning2": "Contract recoveries contains multiple payment streams",
	"ContractBulkEditValidationWarning3": "The contract must not have multiple payment streams",
	"ContractBulkEditValidationWarning4": "The payment stream type is not supported",
	"NumberOfValidationErrors": "There are {} validation errors",
	"CalculationStarted": "Calculation started",
	"NoTaxInformationAvailable" : "There are no tax information available for the property",
	"Postcode": "Postcode",
	"TotPropertyCostM2": "Total Property Cost per m²",
	"FirstYearInvestments": "First Year Investments",
	"NoLatestPropertyVersionAvailable": "No property version is known as the latest one",
	"AddLatestVersion": "Add latest version",
	"ESG_sustainable_investment": "The property is a sustainable investment",
	"PropertyEnergyClass": "Energy class",
	"EnergyDeclarationID": "ID",
	"EnergyDeclarationIssuedDate": "Energy declaration issued date",
	"EnvironmentalImpact": "Environmental impact",
	"EnergyDeclarations": "Energy Declarations from Boverket",
	"EnergyDeclarationText1": "This data is fetched from the Boverket API. If no data is fetched then Boverket does not have any energy declarations for the property. To fetch the data the property must have a County.",
	"PrimaryEnergyNumber": "Primary energy number",
	"EnergyPerformance": "Energy performance",
	"YearOfConstruction": "Year of construction",
	"SpecificPropertyNameText": "Enter specific property name",
	"PropertyDesignation": "Property Name",
	"BoverketErrorMessageSection1": "We were not able to fetch data from Boverket with the county",
	"BoverketErrorMessageSection2": "and property name",
	"BoverketInfoMessageSection1": "Fetching data from Boverket with the county",
	"BoverketInfoMessageSection2": "and property name",
	"VentilationControl": "Ventilation control",
	"RadonMeasurement": "Radon measurement",
	"InvalidContracts": "Number of contracts that can't be updated",
	"RecoveryShortcutTypesTurnover": "Turnover recovery",
	"EnvironmentalCertificates": "Env. certificates",
	"Level": "Level",
	"Moms": "VAT",
	"FirstYearSqm": "SEK/m² year1",
	"CalculationInterestStreamPercentage": "Calculation interest stream (%)",
	"CalculationRestValuePercentage": "Calculation interest rest value (%)",
	"EstimatedMarketValueSqm": "Estimated market value, {currency}",
	"ValueChangeKr": "Value change, {currency}",
	"ValueChangePercentage": "Value change (%)",
	"CompareVersion": "Compared to version:",
	"CurrentVersion": "Current version",
	"Maintenance":"Maintenance",
	"Administration":"Administration",
	"Sum":"Summed",
	"GreenDeal": "Green deal",
	"NormalizedInvestmentDelta":"Whereof diff Investments, {currency}",
	"TenantImprovement": "Tenant Improvement",
	"ShowAmountColumn": "Area amounts column",
};