export function formatDecimalNumber(value: number, locale: string, decimals: number, minDecimals: number, percentage: boolean): string {
    let options: Intl.NumberFormatOptions = {
        style: 'decimal',
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: decimals
    };
    let val = Intl.NumberFormat(locale, options).format(value * (percentage ? 100 : 1));
    return val;
}

export function reverseFormatNumber(val: any, locale: string, langId: number): any {
    var reversedVal: any;
    // Strip non digits from val, but keep decimal separators
    switch (langId) {
        case 1: reversedVal = val.replace(/[^\d&\,\-\−]/g, '');
        break;
        case 2: reversedVal = val.replace(/[^\d&\.\-\−]/g, '');
        break;
    }
    var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
    var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
    reversedVal = reversedVal.replace(new RegExp('\\' + group, 'g'), '');
    reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
    reversedVal = reversedVal.replace("−", "-");
    return reversedVal;
}

export function isNumeric(value: any): boolean {
    return !isNaN(value - parseFloat(value));
}

export function round(value: number, decimals: number): number {
    let exp = Math.pow(10, decimals);
    return parseFloat((Math.round(value * exp) / exp).toFixed(decimals));
}