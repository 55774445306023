export const transSv = {
	"Account": "Konto",
	"Add": "Lägg till",
	"AddModule": "Lägg till modul",
	"AddProperty": "Ny fastighet",
	"AddPropertyVersion": "Ny fastighetsversion",
	"AddPortfolio": "Ny portfölj",
	"AddToFavorites": "Lägg till i favoriter",
	"AdjustedIncrement": "Anpassad uppräkning",
	"AdvancePayment": "Förskottsbetalning",
	"AdvancePaymentShort": "Fsk.",
	"Advanced": "Avancerad",
	"AllVersions": "Alla versioner",
	"Alternative": "Alternativ",
	"AmortizationInformation": "Amorteringsinformation",
	"AmortizationType": "Amorteringstyp",
	"Amount": "Belopp",
	"Area": "Yta",
	"Area2": "Area",
	"AreaAmount": "Antal",
	"Areas": "Områden",
	"AvailableTypes": "Tillgängliga typer",
	"BaseData": "Basdata",
	"BaseNumber": "Bastal",
	"BaseRent": "Bashyra",
	"BaseTax": "Bastax",
	"BaseValues": "Basvärden",
	"Basemonth": "Basmånad",
	"Benefit": "Fördel",
	"BuildingDevRights": "Byggrätter",
	"Burden": "Belastning",
	"CI": "Övriga K/I-poster",
	"Calculate": "Beräkna",
	"Calculation": "Kalkyl",
	"CalculationEnd": "Kalkylslut",
	"CalculationEndShort": "KS",
	"CalculationError": "Beräkningen misslyckades. Kontrollera versionen",
	"CalculationInterest": "Kalkylränta",
	"CalculationPeriod": "Kalkylperiod",
	"CalculationPeriods": "Kalkylperioder",
	"CalculationPortfolio": "Nuvärde portfölj",
	"CalculationStart": "Kalkylstart",
	"CalculationVersion": "Nuvärde fastighet",
	"Calculations": "Kalkyler",
	"Cancel": "Avbryt",
	"CapitalExpenditure": "Grundinvestering",
	"CarePremises": "Vårdlokaler",
	"CashFlow": "Kassaflöde",
	"CashFlowAnalysis": "Kassaflödesanalys",
	"Change": "Ändra",
	"ChangeFirstIncrement": "Ändra första uppräkning",
	"ChangePortfolio": "Byt portfölj",
	"ChangePosition": "Ändra position",
	"ChangeSortOrder": "Ändra sorteringsordning",
	"ChangeVersion": "Byt version",
	"ClassificationHeader": "Läge, standard och skick",
	"Clause": "Klausul",
	"Clear": "Rensa",
	"ClickToEditPicture": "Klicka för ändra bild",
	"Close": "Stäng",
	"CollapseAll": "Minimera alla",
	"Collection": "Bestånd",
	"ColumnBreakContinuous": "Kolumnbrytning (kontinuerlig)",
	"ColumnDefinition1": "Kolumndefinition - en kolumn",
	"ColumnDefinition2": "Kolumndefinition - två kolumner",
	"Comment": "Kommentar",
	"InternalComment": "Intern kommentar",
	"CommonInstallation": "Gemensamhetsanläggning",
	"CommunityAssociation": "Samfällighet",
	"Condition": "Skick",
	"Confirm": "Bekräfta",
	"ConfirmCopyPortfolio": "Portföljen '{0}' kommer kopieras, vill du fortsätta?",
	"ConfirmDeleteGroup": "Vill du ta bort gruppen?",
	"ConfirmDeleteImage": "Vill du ta bort bilden?",
	"ConfirmDeleteModule": "Vill du ta bort modulen?",
	"ConfirmDeletePropertyVersion": "Vill du ta bort fastighetsversion: ",
	"ConfirmDeleteRow": "Vill du ta bort raden?",
	"ConfirmDeleteRowTable": "Vill du ta bort raden?",
	"ConfirmDeleteUserDefArea": "Vill du ta bort området?",
	"ConfirmDiscardChanges": "Det finns ändringar som inte har sparats. Vill du fortsätta?",
	"ConfirmExitYieldUpdate": "Vill du uppdatera både ström och restvärdes räntesatser?",
	"ConfirmImportContracts": "Den valda filen kommer importeras, vill du fortsätta?",
	"ConfirmImportPortfolio": "Den valda filen kommer importeras, vill du fortsätta?",
	"ConfirmLockPortfolioVersions": "Samtliga versioner i aktuell portfölj kommer låsas. Vill du fortsätta?",
	"ConfirmPastePortfolioVersions": "Alla versioner från '{0}' kommer att kopieras till portfölj {1}. Vill du fortsätta?",
	"ConfirmPrognosisParameterDelete": "Alla underliggande prognoser kommer tas bort. Vill du fortsätta?",
	"ConfirmPropertyDataImport": "En avgift tillkommer vid import av fastighetsinformation. Vill du fortsätta?",
	"ConfirmPropertyDetailsLink": "Nuvarande fastighetsinformation kommer ersättas med importerat data. Vill du fortsätta?",
	"ConfirmPropertyDetailsUnlink": "Vill du koppla ifrån länken till importerat data? Ingen information kommer gå förlorad.",
	"ConfirmPropertyPositionChange": "Vill du uppdatera fastighetens ({property}) position?",
	"ConfirmRemoveContracts": "Vill du ta bort markerade kontrakt?",
	"ConfirmRemoveCostIncomes": "Vill du ta bort markerade K/I-poster?",
	"ConfirmRemoveItem": "Vill du ta bort '{0}'?",
	"ConfirmRemoveProperty": "Vill du ta bort fastigheten '{0}'?",
	"ConfirmReplaceFile": "Filnamnet finns redan. Ersätt målet?",
	"ConfirmSetVacancyFields": "Ta även bort kontraktets tillägg och uppdatera övriga fält till vakanta?",
	"ConfirmSortOrderChange": "Vill du ändra sorteringsordningen för aktuell rad?",
	"ConfirmUndoChanges": "Vill du ångra ändringar?",
	"ConfirmUnlockPortfolioVersions": "Samtliga versioner i aktuell portfölj kommer låsas upp. Vill du fortsätta?",
	"ConfirmUpdateFirstIncrementByPortfolio": "Uppdatera första uppräkning för alla kontrakt?",
	"ConfirmUpdateFirstIncrementByVersions": "Uppdatera första uppräkning för alla tillägg i versioner?",
	"ConfirmUpdateSelectedContracts": "Vill du uppdatera markerade kontrakt?",
	"ConfirmUpdateSelectedCostIncomes": "Vill du uppdatera markerade K/I-poster?",
	"ConfirmUpdateSelectedProperties": "Vill du uppdatera versionens datum?",
	"ConstructionPlan": "Stadsplan",
	"ConstructionPlanTypeList_Conditionsplan": "Stadsplan",
	"ConstructionPlanTypeList_ConstructionPlan": "Byggnadsplan",
	"ConstructionPlanTypeList_DetailPlan": "Detaljplan",
	"ConstructionYear": "Byggår",
	"Content": "Innehåll",
	"Contract": "Kontrakt",
	"ContractEnd": "Kontraktslut",
	"ContractImportAddedMessage": "Filen har lagts till i kön för kontraktsimport",
	"ContractImportError": "Ett fel uppstod under kontraktimporten",
	"ContractPeriod": "Avtalstid",
	"ContractStart": "Kontraktstart",
	"ContractView_List_Addition": "Tlg.",
	"ContractView_List_BaseRent": "Bashyra",
	"ContractView_List_CalculationEnd": "K. slut",
	"ContractView_List_PaymentType": "Bet. typ",
	"ContractView_List_PremisesId": "Lokal Id",
	"ContractView_List_PremisesType": "Lokaltyp",
	"ContractView_TabContract_CancelAtLastStreamEnd": "Avbr. v. sista str. slut",
	"ContractView_TabContract_FirstStream": "Värden för 1:a ström",
	"ContractView_TabContract_UseMarketRent": "Använd marknadshyra",
	"ConversionFactor": "Omr. tal",
	"Copy": "Kopiera",
	"CopyHasBeenCreatedOf": "En kopia har skapats av: ",
	"CopyPortfolio": "Kopiera portfölj",
	"CopyPropertyVersion": "Kopiera fastighetsversion",
	"CopyToOtherProperty": "Kopiera till annan fastighet",
	"CopyVersions": "Kopiera versioner",
	"CorporateIdentityNumber_Short": "Org. nr.",
	"Correction": "Korrigering",
	"CostIncome": "K/I-post",
	"CostIncomes": "K/I-poster",
	"CostIncomeShortcutInvestments": "Investeringar",
	"CostIncomeShortcutTenantImprovement": "HG-anpassningar",
	"CostIncomeShortcutLeaseholdFee": "Tomträttsavgäld",
	"Costs": "Kostnader",
	"Count": "Antal",
	"Counties": "Kommuner",
	"Country": "Land",
	"County": "Kommun",
	"Create": "Skapa",
	"CreateValuation": "Skapa utlåtande",
	"CreateValuationReport": "Skapa utlåtanderapport",
	"Created": "Skapad",
	"Currencies": "Valutor",
	"Currency": "Valuta",
	"Customer": "Kund",
	"Danish": "Danska",
	"Database": "Databas",
	"Date": "Datum",
	"DayOfLetting": "Upplåtelsedag",
	"DeductingExpenses": "Avdrag för kostnader",
	"Delete": "Radera",
	"DeleteDependency": "Ta bort beroende",
	"DeleteImage": "Radera bild",
	"DeleteModule": "Ta bort modul",
	"DeleteSelected": "Radera valda",
	"Denotation": "Beteckning",
	"Description": "Beskrivning",
	"DetailedDevPlan": "Detaljplan",
	"DetailedStreams": "Detaljerade strömmar",
	"Details": "Detaljer",
	"Development": "Utveckling",
	"DevelopmentNotInVersion": "Utvecklingstypen är ej aktiv i fastighetsversionen",
	"DevelopmentType": "Utvecklingstyp",
	"DevelopmentTypes": "Utvecklingstyper",
	"DevelopmentTypesInVersion": "Typer i versionen",
	"Display most recent": "Visa senaste",
	"DownloadTrattTemplate": "Hämta importmall",
	"DuplicateContract": "Duplicera kontrakt",
	"E-mail": "E-post",
	"Easement": "Servitut",
	"Edit": "Ändra",
	"EditCopyMessage": "Editerar kopia av ",
	"EditFirstIncrement": "Ändra första uppräkning",
	"EditIncrementDateByPortfolio": "Ändra första uppräkning för versioner",
	"EditLoan": "Ändra lån",
	"EditProfilePicture": "Redigera profilbild",
	"EditPropertyVersion": "Ändra fastighetsversion",
	"EditSelectedContracts": "Ändra flera kontrakt",
	"EditContractsMarketRent": "Ändra marknadshyror",
	"EditSelectedCostIncomes": "Ändra flera KI-poster",
	"EditSelectedPropertyVersions": "Ändra fastighetsversioner",
	"EditUser": "Ändra användare",
	"EditingNewPortfolio": "Ny portfölj redigeras",
	"Employer": "Uppdragsgivare",
	"End": "Slut",
	"EndDate": "Slutdatum",
	"EndOfStream": "Strömslut",
	"English": "Engelska",
	"EnterAreaName": "Ange områdesnamn",
	"EnterPortfolio": "Skriv portföljnamn",
	"EnterSearchText": "Skriv söktext...",
	"Equity": "Eget kapital",
	"EquityAtCalculationEnd": "Eget kapital vid kalkylslut",
	"EquityAtCalculationStart": "Eget kapital vid kalkylstart",
	"Error": "Fel",
	"ErrorActionFailed": "Åtgärden kunde inte utföras",
	"ErrorMessage_InterestRateInvalidDates": "Datum måste var mindre än eller lika med lånets startdatum",
	"ErrorMessage_InterestRatesMissing": "Minst en räntesats måste finnas",
	"ErrorMessage_NoCalculationsFound": "Versionen är inte beräknad",
	"ErrorMessage_NoInvestmentCalcFound": "För att göra en investeringskalkyl måste köpeskilling vara större än 0",
	"ErrorMessage_RecoveryEditInvalidContract": "Tillägget kan inte skapas/ändras, kontraktet innehåller valideringsfel.",
	"ErrorMessage_VersionLocked": "Fastighetsversionen är låst, ändringar är inte tillåtna.",
	"ErrorUnexpected": "Oväntat fel",
	"EstimatedMarketValue": "Bedömt marknadsvärde, {currency}",
	"Examiner": "Besiktningsman",
	"ExcludeSubsidies": "Exkludera räntebidrag",
	"ExcludeTaxes": "Exkludera skatter",
	"NewIdentifier": "Ny versionsidentifikator",
	"UseVersionIdentifiersFromImport": "Använd versionsidentifikatorer från import",
	"ExitYield": "Exit yield",
	"ExitYieldPercentage": "Exit yield (%)",
	"ExpandAll": "Expandera alla",
	"Export": "Exportera",
	"ExportPortfolio": "Exportera portfölj",
	"ExportShort": "Export",
	"ExportToCurrentProperty": "Exportera till vald fastighet",
	"Download": "Hämta till dator",
	"Favorites": "Favoriter",
	"File": "Fil",
	"Filter": "Filtrera",
	"FilterName": "Filtrera namn",
	"FilterEmail": "Filtrera e-post",
	"Finish": "Finska",
	"FirstIncrementContracts": "Första uppräkning (kontrakt)",
	"FirstIncrementMonth": "1. uppr.",
	"FirstIncrementMonth_Long": "Första uppräkning",
	"FirstIncrementRecoveries": "Första uppräkning (tillägg)",
	"FixedAdjustment": "Fast uppräkning",
	"FixedAmortization": "Fast amortering",
	"FixedTo": "Bunden till",
	"FloorPlan": "Planlösning",
	"Flow": "Ström",
	"Frequency": "Frekvens",
	"FrequencyAmortizement": "Frek. amortering",
	"FrequencyInterest": "Frek. ränta",
	"From": "Från",
	"FromArea": "Från yta",
	"FromProperty": "Från fastighet",
	"FromVersion": "Från version",
	"Frontpage": "Framsida",
	"FrontpageNoSummary": "Framsida utan sammanfattning",
	"Function": "Funktion",
	"Funnel": "Tratten",
	"Garage": "Garage",
	"General": "Allmän",
	"GenerateReport": "Generera rapport",
	"GenerateReports": "Generera rapporter",
	"GeneratedFiles": "Skapade filer",
	"GetPropertyVersion": "Hämta fastighetsversion",
	"GroundLease": "Arrende",
	"Group": "Grupp",
	"Groups": "Grupper",
	"HasArea": "Har yta",
	"HideGridLines": "Dölj rutnät",
	"High": "Hög",
	"Housing": "Bostäder",
	"HttpRequestErrorMessage": "Ett fel uppstod under fjärranropet. Meddelande: {message}. Data: {data}.",
	"HttpRequestErrorTitle": "Fel vid fjärranrop",
	"IPD": "IPD",
	"IPD6": "IPD 6",
	"IPDNumber": "IPD nummer",
	"IPDPropertyVersionDialogTitle": "IPD - Fastighetsversion",
	"IPDStatus": "IPD Status",
	"Identifier": "Identifikator",
	"ImageUploadError": "Bildfilen kunde inte laddas upp",
	"Images": "Bilder",
	"Import": "Importera",
	"ImportContracts": "Importera kontrakt",
	"ImportExport": "Import & Export",
	"ImportPortfolio": "Importera portfölj",
	"Import_Link": "Import / länk",
	"InDataFile": "Indatafil",
	"Active": "Aktiv",
	"Inactive": "Inaktiv",
	"IncludeAdjustmentColumn": "Hg-anpassningskolumn",
	"IncludeGreenDeal": "Gröna avtal",
	"IncludeForumLogo": "Inkludera Forum-logga",
	"IncludeFrontpage": "Inkludera framsida",
	"IncludeLoan": "Räkna med lån",
	"IncludeModules": "Inkludera moduler",
	"IncludeTerminationProlongingColumn": "Uppsägnings- & förl. kolumn",
	"IncludeInternalComments": "Interna kommentarer",
	"IncludeRecoveryAdditionColumn": "Ombyggnadstillägg kolumn",
	"Index": "Index",
	"IndexClause": "Indexklausul",
	"IndexClauses": "Indexklausuler",
	"IndexDenotation": "Indexbeteckning",
	"IndexDenotations": "Indexbeteckningar",
	"IndexName": "Indexnamn",
	"IndexPercentage": "Index %",
	"IndexValue": "Indexvärde",
	"IndexValues": "Indexvärden",
	"Industry": "Industri",
	"Inflation": "Inflation %",
	"InspectionDate": "Besiktningsdatum",
	"Instalment": "Avgäld",
	"InstalmentTimeYear": "Avgäldstid (year)",
	"InterestInformation": "Ränteinformation",
	"InterestRatePercentage": "Räntesats (%)",
	"InterestType": "Räntetyp",
	"Interests": "Räntor",
	"Interior1": "Interiör 1",
	"Interior2": "Interiör 2",
	"Interval": "Intervall",
	"InvestmentCalculation": "Investeringskalkyl",
	"InvolvedProperties": "Inblandade fastigheter",
	"Ipd4": "IPD 4",
	"IpdSettings": "IPD inställningar",
	"IrrDistribution": "Fördelning IRR",
	"IrrEquity": "Eget kapital",
	"IrrTotal": "Totalt kapital",
	"JobReady": "{type} '{report}' är nu klar",
	"JobStatus_Canceled": "Avbruten",
	"JobStatus_Failed": "Fel",
	"JobStatus_Finished": "Färdig",
	"JobStatus_InProgress": "Arbetar",
	"JobStatus_Pending": "Väntar",
	"JobType_Calculation": "Kalkyl (version)",
	"JobType_CalculationDetailed": "Detaljerad kalkyl",
	"JobType_CalculationPortfolio": "Kalkyl (portfölj)",
	"JobType_ContractImport": "Kontraktimport",
	"JobType_CopyPortfolio": "Portföljkopiering",
	"JobType_ExportPortfolio": "Portföljexport",
	"JobType_ImportPortfolio": "Portföljimport",
	"JobType_Report": "Rapport",
	"JointOwnershipProperties": "Delägande fastigheter",
	"KeyRatios": "Nyckeltal",
	"KeyRatiosCompact": "Nyckeltal kort",
	"LandLease": "Tomträttsavgäld",
	"LandLease_GroundLease": "Tomträtt/Arrende",
	"Language": "Språk",
	"Latest": "Senaste",
	"LatestVersion": "Senaste version",
	"LeaseArea": "Ytfördelning",
	"LeaseAreaPrimaryPremisesError": "Minst en ytfördelning måste vara av primära lokaltypen",
	"LettedTo": "Upplåten till",
	"Link": "Länka",
	"LinkMap": "Länka karta",
	"Linked": "Länkad",
	"LinkedDate": "Länkad datum",
	"Loan": "Lån",
	"LoanAmount": "Lånebelopp",
	"LoanAtCalculationEnd": "Lån vid kalkylslut",
	"LoanAtCalculationStart": "Lån vid kalkylstart",
	"LoanName": "Lån namn",
	"Location": "Läge",
	"LockVersions": "Lås versioner",
	"Locked": "Låst",
	"LongCalculationInfo": "Optimerar beräkningar",
	"M2": "m²",
	"MainPremisesType": "Huvudlokaltyp",
	"ManageLoan": "Hantera lån",
	"Map": "Karta",
	"MapWithBorder": "Karta med ram",
	"MapSavedErrorNotification": "Ett fel uppstod när bilden skulle exporteras. Felmeddelande: {err} ",
	"MapSavedSuccessNotification": "Kartbild sparad till fastigheten '{property}'",
	"Margin": "Marginal",
	"MarginPercentage": "Marginal (%)",
	"MarketRent": "Marknadshyra",
	"MarketRentIncl": "Marknadshyra inkl tillägg",
	"MarketRents": "Marknadshyrors",
	"MarketRentDate": "M. hyra dat",
	"MarketRentDate2": "Datum marknadshyra",
	"MarketRentShort": "M. hyra",
	"MarketRentM2": "Marknadshyra per m²",
	"MarketSituation": "Marknadssituation",
	"MarketType": "Marknadstyp",
	"MarketTypes": "Marknadstyper",
	"MarketValue": "Marknadsvärde",
	"MaxInvestmentsColumns": "Max antal år - Investeringar",
	"MaxNoiColumns": "Max antal år - Driftnetto",
	"MergeAreaColumns": "Sammanfoga area-kolumner",
	"Message_PropertyDetailsLinked": "Fastigheten är länkad mot extern leverantör",
	"Modern": "Modern",
	"ModuleDependency": "Modulberoende",
	"RemoveWarningModuleDependency": "Du måste först ta bort beroendet från modul '{0}'",
	"ModuleHasBeenDeleted": "Modulen har raderats",
	"Month": "Månad",
	"MonthOfChange": "Ändringsmånad",
	"MonthOfChangeShort": "Mån.",
	"MoreSearchResults": "Sökningen returnerade fler än {max} rader. Försök göra sökningen mer specifik.",
	"MovingInDate": "Inflyttningsdatum",
	"MRICS": "MRICS",
	"MRICStext": "MRICS text",
	"Municipality": "Kommun",
	"MyProfile": "Min profil",
	"Name": "Beteckning",
	"Name2": "Namn",
	"New": "Ny",
	"NewImport": "Ny import",
	"No": "Nej",
	"NoMarketType": "(Ingen marknadstyp)",
	"NoOfDecimals": "Ant. dec",
	"NoOfProlongingMonths_Short": "Antal förläng. mån.",
	"NoOfUnits": "Ant. enh",
	"NoPortfolioSelected": "Ingen portfölj är vald",
	"NoPropertySelected": "Ingen fastighet är vald",
	"NoVersionSelected": "Ingen fastighetsversion är vald",
	"None": "Ingen",
	"Normal": "Normal",
	"NotDefined": "Ingen uppgift",
	"NotLinked": "Ej länkad",
	"NotReady": "Ej klara",
	"NumberOf": "Antal",
	"NumberOfContracts": "Antal kontrakt: ",
	"OK": "OK",
	"ObjectIdentifier": "ObjektNr",
	"Office": "Kontor",
	"Offices": "Kontor",
	"Okay": "Okej",
	"OnlyContracts": "Bara kontrakt",
	"OnlyUp": "End. upp",
	"OrderNo": "Ordningsnr",
	"OtherAssumptions": "Övr. Antaganden",
	"Outdated": 'Varning, uträkningen är utdaterad',
	"OutdatedShort": 'Utdaterad',
	"Parking": "Parkeringsplatser",
	"PassingRent": "Utgående hyra",
	"PassingRentEx": "Utgående hyra exkl tillägg",
	"PassingRentShort": "U. hyra",
	"PassingRentM2": "Utgående hyra per m²",
	"PassingRentExRecM2": "Utgående hyra exkl tillägg per m²",
	"Paste": "Klistra in",
	"PasteVersions": "Klistra in versioner",
	"PaymentFlowType": "Betalningsströmtyp",
	"PaymentFlows": "Betalningsströmmar",
	"PaymentFrequencyShort": "F",
	"PaymentGroup": "Avsnitt",
	"PaymentGroup/PaymentType": "Avsnitt / Betalningstyp",
	"PaymentGroupDivision": "Avsnittsindelning",
	"PaymentGroups": "Avsnitt",
	"PaymentType": "Betalningstyp",
	"PaymentTypes": "Betalningstyper",
	"Percentage": "Procentsats",
	"PercentageAdjustment": "Procentuell uppräkning",
	"Percentages": "Procentsatser",
	"PercentualAmortization": "Procentuell amortering",
	"Period": "Period",
	"Phone": "Telefon",
	"Photo": "Foto",
	"Pick": "Välj",
	"PickAction": "Välj åtgärd",
	"PickDocument": "Välj dokument",
	"PinSizeScale": "Skala för kartnålar",
	"Portfolio": "Portfölj",
	"PortfolioCopyAddedMessage": "Portföljkopieringen har lagts till i kön",
	"PortfolioImportAddedMessage": "Filen har lagts till i kön för portföljsimport",
	"PortfolioImportError": "Ett fel uppstod under portföljimporten",
	"PortfolioName": "Portföljnamn",
	"PortfolioValuation_PreliminaryAssessment": "under särskilda förutsättningar",
	"PortfolioValuations": "Portföljutlåtande",
	"PortfolioVersionsCopyPrepared": "Versioner sparade i urklipp, välj 'Klistra in versioner' för att kopiera till annan portfölj",
	"Portfolios": "Portföljer",
	"PortfoliosView_SearchHeading": "Sök och lägg till fastighetsversioner",
	"PreliminaryAssessment": "Förhandsvärdering",
	"PremisesGroupName": "Gruppnamn",
	"PremisesGroupType": "Typ",
	"PremisesGroups": "Lokalgrupper",
	"PremisesType": "Lokaltyp",
	"PremisesTypes": "Lokaltyper",
	"PresentValue": "Nuvärde",
	"PresentValueSum": "Summa nuvärde",
	"PresentEquity": "Nuvärde eget kapital",
	"PresentValueCashFlow": "Nuvärde Kassaflöde",
	"PresentValueRestValue": "Nuvärde Restvärde",
	"PricePerM2": "Pris / m²",
	"Priority": "Prioritet",
	"ProfitRatio": "Vinstmultipel",
	"Prognosis": "Prognos",
	"PrognosisAlternative": "Prognosalternativ",
	"PrognosisAlternatives": "Prognosalternativ",
	"PrognosisParameter": "Prognosparameter",
	"PrognosisParameterType": "Prognosparametertyp",
	"PrognosisParameterTypes": "Prognosparametertyper",
	"PrognosisParameters": "Prognosparametrar",
	"Prolonging": "Förlängning",
	"ProlongingType": "Förlängningstyp",
	"Properties": "Fastigheter",
	"Property": "Fastighet",
	"PropertyAddressList": "Adresslista fastigheter",
	"PropertyDetails_text1": "I rapporten kommer texten att avslutas med 'Fastigheten har en area om ",
	"PropertyDetails_text2": "Fastigheten är en tomträtt",
	"PropertyIdentifier": "Fastighetsidentifikator",
	"PropertyName": "Fastighetsbenämning",
	"PropertyOwnerRepr": "Fast.ägar repr.",
	"PropertyTax": "Fastighetsskatt",
	"PropertyTaxType": "Fastighetsskattetyp",
	"PropertyTaxTypes": "Fastighetsskattetyper",
	"PropertyVersion": "Fastighetsversion",
	"PropertyVersionDetails": "Fastighetsversion - detaljer",
	"PropertyVersions": "Versioner",
	"PropertyVersionsAreLocked": "Kan ej beräkna, en eller flera versioner är låsta.",
	"Provider": "Leverantör",
	"ProviderPropertyId": "Leverantörens fastighets-id",
	"ProviderPropertyName": "Leverantörens fastighetsbeteckning",
	"Public": 'Publik',
	"PurchasePrice": "Köpeskilling",
	"Purpose": "Ändamål",
	"QueueNo": "Köplats",
	"Rate": "Kurs",
	"Ready": "Klara",
	"RealInterest": "Realränta",
	"RebuiltYearSpecial": "Ombyggd (år, år, ...)",
	"Recoveries": "Tillägg",
	"Recovery": "Tillägg",
	"RecoveryPerM2": "Tillägg per m²",
	"RecoveryShortcutTypesHeat": "Värme",
	"RecoveryShortcutTypesOther": "Övrigt",
	"RecoveryShortcutTypesTax": "Fastighetsskatt",
	"RecoveryAdditionForConversion": "Omb. tillägg",
	"RecoveryAdditionForConversionShort": "Omb. tillägg",
	"RecoveryTurnoverShort": "Oms. tillägg",
	"RecoveryRentDiscounts": "Hyresrabatter",
	"Region": "Län",
	"Regions": "Län",
	"RegisteredOwner": "Registrerad ägare",
	"Remaining": "Återstår",
	"Rent5Year": "Ränta 5år obl. %",
	"RentComposition": "Hyressammanställning",
	"RentExRecoveriesShort": "U. ex tillägg",
	"RentLevel": "Hyresnivå",
	"RentRebates": "Hyresrabatter",
	"RentRecoveryShort": "H. tlg",
	"Report": "Rapport",
	"ReportReady": "Rapporten '{0}' är nu klar",
	"ReportTemplate": "Rapportmall",
	"ReportTypeCalculation": "Kalkyl",
	"ReportTypeContractsToExcel": "Kontraktslista",
	"ReportTypeIpd4": "IPD 4",
	"ReportTypeIpd6": "IPD 6",
	"ReportTypeIpd6NotReady": "IPD6 ej klara",
	"ReportTypeKeyRatios": "Nyckeltal",
	"ReportTypeKeyRatiosShort": "Nyckeltal kort",
	"ReportTypeKeyRatiosExtended": "Nyckeltal (utökad)",
	"ReportTypeSensitivityAnalysis": 'Känslighetsanalys',
	"ReportTypePortfolioDetails": "Portföljdetaljer",
	"ReportTypePropertyAddressList": "Fastigheter adresslista",
	"ReportTypeValuation": "Utlåtande",
	"Reports": "Rapporter",
	"ResidentialShort": "Boende",
	"RestValue": "Restvärde",
	"RestValueRate": "Restvärde räntesats (%)",
	"RestValueYear": "Restvärdeår",
	"Result": "Resultat",
	"Retrieved": "Hämtad",
	"Rights": "Rättigheter",
	"Rounding": "Avrundning",
	"RowDeleteError": "Raden kunde inte tas bort. Fel: ",
	"RowSaveError": "Raden kunde inte sparas. Fel: ",
	"Rows": "Raderna",
	"Save": "Spara",
	"SaveAndClose": "Spara och stäng",
	"SaveAs": "Spara som",
	"Search": "Sök",
	"SearchEmail": "Sök E-post",
	"SearchLocation": "Sök plats",
	"SearchModule": "Sök efter modul",
	"SearchName": "Sök namn",
	"SearchPortfolio": "Sök portfölj",
	"SearchProperty": "Sök fastighet",
	"SearchTemplate": "Sök mall...",
	"SearchUser": "Sök användare",
	"SearchUsername": "Sök användarnamn",
	"Select": "Välj",
	"SelectContract": "Välj kontrakt",
	"SelectCostIncome": "Välj K/I-post",
	"SelectFavorite": "Välj bland favoriter...",
	"SelectPropertyVersion": "Välj fastighetsversion",
	"SelectRecent": "Välj bland senaste...",
	"SelectReport": "Välj rapport",
	"SelectUser": "Välj användare",
	"Selected": "Valda",
	"SelectedContracts": "Valda kontrakt",
	"SetDependency": "Sätt beroende",
	"Settings": "Inställningar",
	"Settlement": "Bebyggelse",
	"Share": "Andel",
	"ShareOf": "Andel av",
	"ShareOfPaymentPercentage": "Andel %",
	"Shares": "Andelar",
	"ShowLatest": "Visa senaste",
	"ShowVersions": "Visa versioner",
	"Sign": "Tecken",
	"SignOut": "Logga ut",
	"SigningDate": "Underskriftsdatum",
	"Signature": "Underskrift",
	"SiteAndLocation": "Tomt och läge",
	"SiteMap": "Tomtkarta",
	"SiteOwner": "Tomtägare",
	"SortByName": "Sortera efter namn",
	"SortByValue": "Sortera efter värde",
	"Standard": "Standard",
	"Start": "Start",
	"StartAmount": "Ingångsbelopp",
	"StartAmountShort": "Ing. bel",
	"StartAmountUpdated": "Ingångsbelopp uppdaterat!",
	"StartDate": "Startdatum",
	"StartInterest": "Startränta",
	"StartTime": "Starttid",
	"Status": "Status",
	"StatusFailed": "Fel",
	"StatusFinished": "Färdig",
	"StatusStarted": "Startad",
	"Statuses": "Statusar",
	"Stream": "Ström",
	"StreamRate": "Kassaflöde räntesats (%)",
	"Subcounty": "Kommundel",
	"Subcounties": "Kommundelar",
	"Swedish": "Svenska",
	"SysAdminPermissionText": "THIS USER HAS FULL PERMISSION ON ALL USER DATABASES",
	"TableOfContents": "Innehållsförteckning",
	"TaxInfo": "Taxeringsuppgifter",
	"TaxRate": "Skattesats",
	"TaxRecoveries": "Skattetillägg",
	"TaxRecoveryType": "Skattetilläggstyp",
	"TaxType": "Skattetyp",
	"TaxTypeCodes": "Skattetypkoder",
	"TaxValuationUnit": "Skattevärderingsenhet",
	"Tenant": "Hyresgäst",
	"TerminationDate": "Uppsägningstid",
	"Title": "Titel",
	"TitleEng": "Engelsk titel",
	"To": "Till",
	"ToArea": "Till yta",
	"ToDatabase": "Till databas",
	"ToExcel": "Till Excel",
	"ToIPD": "Till IPD",
	"ToProperty": "Till fastighet",
	"ToVersion": "Till version",
	"TotalArea": "Totalyta",
	"TotalEquity": "Totalt kapital",
	"TotalIndexValues": "Totalt antal indexvärden",
	"Trading": "Handel",
	"TransactionCosts": "Transaktionskostnader",
	"Tree": "Träd",
	"Type": "Typ",
	"TypeCode": "Typkod",
	"TypeOfPayment": "Betalningstyp",
	"TypeOfPaymentEmpty": "Inga betalningstyper kunde hittas",
	"TypeOfPaymentFilter": "Betalningstyp - filter",
	"TypeOfPaymentGroups": "Betalningsgrupper",
	"TypeOfPaymentVacant": "Betalningstyp (vakans)",
	"TypeOfPayments": "Betalningstyper",
	"Undo": "Ångra",
	"Unit": "Enhet",
	"Unknown": "Okänt",
	"Unlink": "Avlänka",
	"UnlockVersions": "Lås upp versioner",
	"UntilCalculationEnd": "Till kalkylslut",
	"Update": "Uppdatera",
	"UpdateStarttimes": "Uppdatera starttider till:",
	"UpdateVersionsInPortfolio": "Uppdatera versioner",
	"Updated": "Uppdaterad",
	"UpdatedAlt": "Uppdaterade",
	"Use": "Användning",
	"User": "Användare",
	"UserDefinedAreas": "Mina områden",
	"Username": "Användarnamn",
	"Users": "Användare",
	"UtilityEasement": "Ledningsrätt",
	"VAT": "Moms",
	"VAT_Soc": "Org./Personnr",
	"Vacancies": "Vakanser",
	"Vacant": "Vakant",
	"VacantArea": "Vakant yta",
	"ValidationContract1": "Den första strömmens startdatum måste vara mindre än eller lika med kontraktets startdatum",
	"ValidationContract2": "Den sista strömmens slutdatum måste vara större än eller lika med kontraktets slutdatum",
	"ValidationContract3": "Kontraktets startdatum måste vara mindre än eller lika med kontraktets slutdatum",
	"ValidationErrorNavigation": "Det finns valideringsfel som behöver åtgärdas",
	"ValidationErrors": "Valideringsfel",
	"ValidationLoan1": "Lånets startdatum måste vara lika med eller mindre än lånets slutdatum",
	"ValidationMarketValueMinZero": "Köpeskilling måste vara större än 0",
	"ValidationNumeric": "Värdet måste vara numeriskt",
	"ValidationPositiveZero": "Värdet måste vara >= 0",
	"ValidationRange": "Värdet måste vara mellan {0} och {1}",
	"ValidationRecovery1": "Tilläggets startdatum måste vara mindre än eller lika med tilläggets slutdatum",
	"ValidationRequired": "Obligatorisk",
	"ValidationStartDateLessThanEndDate": "Startdatum måste vara mindre än slutdatum",
	"ValidationStream1": "Strömmens startdatum måste vara mindre än eller lika med strömmens slutdatum",
	"ValidationStream2": "Strömmars datum får inte överlappa",
	"ValidationStream3": "Det får inte finnas mellanrum mellan intilliggande strömmar",
	"ValidationStream4": "Strömmens startdatum måste vara mindre än eller lika med första uppräkning",
	"ValidationStream5": "Det måste finnas minst ett variabelt belopp",
	"ValidationStream6": "Flera variabla belopp får inte innehålla samma datum",
	"ValidationStream7": "Ett eller flera valideringsfel i variabla belopp",
	"ValidationStream8": "Ett eller flera fel i variable procentsatser",
	"ValidationStream9": "Minst en procentuell uppräkning måste ha datum som är mindre än eller lika med strömmens startdatum",
	"ValidationStream10": "Det måste finnas minst en ström",
	"ValidationStream11": "Enbart den sista strömmen kan vara satt 'till kalkylslut'",
	"ValidationTaxes1": "En fastighetsskatt måste innehålla minst en skattevärderingsenhet",
	"ValidationTaxes2": "Antingen utvecklingstyp eller prognosparameter måste anges",
	"ValidationTaxes3": "En skattevärderingsenhet måste innehålla minst en skattesats",
	"ValidationTaxes4": "Minst en skattesats måste ha ett datum som är mindre än eller lika med versionens kalkylstart",
	"Validation_leaseAreaUsesOverrideAmount": "Ytfördelningen använder \"Värde\"-egenskapen som överrider hyressättningen",
	"ValidationWarning": "Varning",
	"Valuated": "Värderad",
	"Valuation": "Utlåtande",
	"ValuationDate": "Värderingstidpunkt",
	"ValuationUnit": "Värderingsenhet",
	"Valuations": "Utlåtanden",
	"Valuator": "Värderare",
	"Value": "Värde",
	"ValueBuildRight": "Värde byggrätt",
	"ValueYear": "Värdeår",
	"Valuer": "Värderare",
	"VariableAmount": "Variabel belopp",
	"VariableAmountStreamAdvancedGUI": "Variabel procentuell (utökad)",
	"Version": "Version",
	"VersionsCount": "Antal versioner",
	"Warehouse": "Lager",
	"WarningEditingMultiple": "Varning, du ändrar i flera versioner.",
	"WarningUpdateFirstIncrementByPortfolio": "Varning, du ändrar första uppräkning i flera versioner och dess kontrakt",
	"Warning_onePhotoPerModule": "En modul kan endast innehålla en bild/graf",
	"WrongFileType": "Fel fil typ",
	"WrongFormat": "Felaktigt format",
	"YearlyAmount": "Årligt belopp",
	"YearlyCost": "Årskostnad",
	"YearlyRevenue_CostForObj": "Årlig intäkt/kostnad för obj",
	"Yes": "Ja",
	"ZipCode": "Postnummer",
	"existingLoan": "Befintligt Lån",
	"importFromDetails": "Hämta inlagda tax.uppg",
	"publicUserDefinedAreas": "Områden",
	"Always": "Alltid",
	"AreaDistributionGraph": "Ytfördelning",
	"AreaDistributionVacancyGraph": "Ytfördelning (Vakans)",
	"AvailableDataTables": "Tillgängliga datatabeller",
	"AvailableFields": "Tillgängliga fält",
	"AvailableTables": "Tillgängliga tabeller",
	"ColumnBreak": "Kolumnbrytning",
	"ConfirmAmortizationTypeChange": "Byte av typ kommer nollställa listan med amorteringar",
	"ConfirmValuationInstance": "Det finns osparade ändringar, vill du spara?",
	"ConfirmValuationModule": "Det finns osparade ändringar, vill du spara?",
	"ContractedAssessedRentGraph": "Kontrakterad och bedömd hyra",
	"Countries": "Länder",
	"DataTag": "Data: ",
	"DiscretionaryHyphen": "Mjukt bindestreck",
	"DisplayInIndex": "Visa i index",
	"DisplayModule": "Visa modul",
	"DisplayMoreSettings": "Visa fler",
	"FrontPage": "Framsida",
	"FutureEconomicVacancyGraph": "Framtida Ekonomisk Vakans",
	"Get": "Hämta",
	"GetLandLease": "Hämta tomträtt",
	"Graph": "Graf",
	"Graphs": "Grafer",
	"HeaderOne": "Rubrik 1",
	"HeaderText": "Rubriktext",
	"HeaderThree": "Rubrik 3",
	"HeaderTwo": "Rubrik 2",
	"IfAllDataExist": "Om all data finns",
	"IfDataExist": "Om data finns",
	"IfPrelAssessment": "Om förhandsvärdering",
	"IfPreviousModuleWritten": "Om föregående modul finns",
	"IfPreviousModuleNotWritten": "Om föregående modul inte finns",
	"IndexAppendix": "Innehållsförteckning",
	"Insert": "Infoga",
	"Module": "Modul",
	"ModuleHeader": "Modulrubrik",
	"ModuleText": "Modultext",
	"Modules": "Moduler",
	"NonBreakingSpace": "Hårt mellanslag",
	"NumberOfContractsGraph": "Antal kontrakt",
	"OperationGraph": "Drift",
	"OperationNetGraph": "Driftnetto",
	"Operations": "Drift",
	"PageBreakAfterModule": "Sidbrytning efter modulen",
	"PageBreakBeforeModule": "Sidbrytning före modulen",
	"PageFooter": "Sidfot",
	"PageHeader2": "Sidhuvud",
	"Paragraph": "Paragraf",
	"PercentageGraph": "Procent av utgående hyra",
	"PhotoTag": "Foto: ",
	"Photos": "Foton",
	"StreamRateGraph": "Kassaflöde",
	"TableTag": "Tabell: ",
	"UnSupportedBrowserWarning": "Denna webbläsare är inte rekommenderad. Vänligen använd den senaste versionen av Google Chrome",
	"UnSupportedBrowserError": "VdokEditor fungerar endast med Google Chrome",
	"UnkownType": "Oklar typ",
	"UseModuleAs": "Använd modul som",
	"ValuerFirst": "Värderare ett",
	"ValuerSecond": "Värderare två",
	"Year": "År",
	"Zoning": "Detaljplan",
	"ExcelReport": "Rapport - Excel",
	"OptimizedCalculation": "Optimerad beräkning - Beta",
	"Calculated": "Beräknad",
	"LoanDetails": "Låndetaljer",
	"PropertyTemplate": "Fastighetsmall",
	"UseAsPropertyTemplate": "Använd som fastighetsmall",
	"Address": "Adress",
	"EditVersionStartEnd": "Ändra versionsstart och slut",
	"EditCalculationPeriods": "Ändra kalkylperioder",
	"EditMarketRentStart": "Ändra starttid för marknadshyra",
	"EditCostIncomesFirstIncrement": "Ändra K/I-posters första uppräkning",
	"ConfirmUpdateSelectedPropertiesCalculationPeriods": "Vill du uppdatera versionens kalkylperioder?",
	"ConfirmUpdateSelectedPropertiesCostIncomesFirstIncrement": "Vill du uppdatera versionens K/I-posters första uppräkning?",
	"ConfirmUpdateSelectedPropertiesMarketRentStart": "Vill du uppdatera versionens starttid för marknadshyra?",
	"Permission": "Behörighet",
	"Permissions": "Behörigheter",
	"PermissionGroup": "Behörighetsgrupp",
	"AppRight_2": "Import/Export",
	"AppRight_3": "VDok",
	"AppRight_4": "VDok Editor",
	"AppRight_6": "Fia",
	"AppRight_14": "Karta",
	"AppRight_15": "Kopiera portfölj till",
	"AppRight_16": "Prognoser",
	"AppRight_17": "Integration mot Metria",
	"AppRight_18": "Ändra data",
	"AppRight_19": "Ändra data",
	"AppRight_20": "Läsa basdata",
	"AppRight_21": "Ändra basdata",
	"NotSpecified": "Ej angivet",
	"Email": "E-post",
	"ProfilePicture": "Profilbild",
	"AnUnexpectedErrorOccurred": "Ett oväntat fel uppstod",
	"YieldYear1Percentage": "Direktavkastning innevarande år (%)",
	"YieldYear1": "Direktavkastning innevarande år, %",
	"InitialYield":"Direktavkastning initialt, %",
	"NormalizedYield":"Normaliserad direktavkastning, %",
	"VTB":"K/T",
	"GrossIncomeMultiplier":"Bruttokapitaliseringsfaktor",
	"MarketValueSqmCurrency": "Marknadsvärde, {currency}",
	"MarketValueM2": "Marknadsvärde per m²",
	"InPortfolio": "Tillhör portfölj",
	"ErrorMessage_RecoveryMultipleAdd": "Kontraktet måste sparas innan du kan skapa ett ytterligare tillägg.",
	"SearchContract": "Sök kontrakt",
	"DiscountRate": "Kalkylränta",
	"DuplicateMarketRents": "Det finns flera hyror med samma marknadstyp ({types}) i listan.",
	"Multiple": "Flera",
	"UsedByCount": "Används av (antal)",
	"NewValue": "Nytt värde",
	"Current": "Värde",
	"MarketRentsUpdated": "Marknadshyror uppdaterade",
	"PasteErrorMultipleColumns": "Du kan endast kopiera en kolumn i taget",
	"CompareWithOtherPortfolio": "Jämför med annan portfölj",
	"Discount": "Rabatt",
	"TaxWithdrawal": "Skatteuttag",
	"RecoveriesUpdated": "Tillägg uppdaterade",
	"ValueChange": "Förändring",
	"AdjustRecoveries": "Justera tillägg",
	"Error_MultiplePaymentStreams": "Tillägget innehåller flera betalningsströmmar och kommer inte uppdateras",
	"Error_MultipleLeaseAreas": "Kontraktet innehåller flera ytfördelningar",
	"Error_StreamUsesDevelopment": "Strömmen använder utveckling istället för prognos",
	"Error_IndexStreams": "Tillägget innehåller en eller flera indexströmmar och kommer inte uppdateras",
	"Warning_MultipleRecoveries": "Det finns flera tillägg av denna typ. Överflödiga tillägg kommer att raderas",
	"Warning_WillBeRemoved": "Tillägget kommer att tas bort",
	"FirstIncrementNewRecoveries": "Första uppräkning nya tillägg",
	"ShowDayInReports": "Visa dag i rapporter",
	"MiscRecovery": "Övr. tillägg",
	"Misc": "Övrigt",
	"HeatingAndCooling": "Värme & kyla",
	"MarketRentNotInUse": "Marknadshyra används ej",
	"GrossArea": "Areal",
	"Warning_OutOfMarketTypes": "Det gick inte att skapa en ny marknadshyra, det finns inga lediga marknadstyper.",
	"SaveAndCalculate": "Spara & beräkna",
	"BadRequest_CalculationRequired": "För att använda den här funktionen måste du först göra en beräkning.",
	"BadRequest_RecalculationRequired": "Beräknad data är utdaterad. Utför en ny beräkning och försök igen.",
	"CalculationRequired": "Beräknad data är utdaterad. Utför en ny beräkning och försök igen",
	"Warning_VacancyStreamStartLessThanCalcStart": "Det finns KI-poster med betalningsströmtyp 'Andel av' där starttiden är tidigare än kalkylstart",
	"Error_InactivateMarketType": "Marknadstypen används som standardvärde till en eller flera lokaltyper.",
	"CalculatedDatas": "Beräknade data",
	"InitialVacancy": "Initial Vakans",
	"EditAll": "Ändra alla",
	"EditContracts": "Ändra kontrakt",
	"ComputedColumn": "Beräknad Kolumn",
	"NotFound": "Hittades inte",
	"MoveStreamDates": "Flytta alla strömdatum * månader",
	"ClearInternalComments": "Rensa interna kommentarer",
	"WarningUnsavedChanges": "Det finns osparade ändringar",
	"ContractBulkEditValidationWarning": "Kontraktet måste ha exakt en ytfördelning",
	"ContractBulkEditValidationWarning2": "Kontraktets tillägg innehåller flera betalningsströmmar",
	"ContractBulkEditValidationWarning3": "Kontraktet får inte ha flera betalningsströmmar",
	"ContractBulkEditValidationWarning4": "Strömtypen stöds ej",
	"NumberOfValidationErrors": "Det finns {} valideringsfel",
	"CalculationStarted": "Beräkning påbörjad",
	"NoTaxInformationAvailable" : "Det finns inga taxeringsuppgifter för fastigheten",
	"Postcode": "Postkod",
	"TotPropertyCostM2": "Total fastighetskostnad per m²",
	"FirstYearInvestments": "Första årets investeringar",
	"AddLatestVersion": "Lägg till senaste versionen",
	"NoLatestPropertyVersionAvailable": "Ingen version är markerad som den senaste",
	"ESG_sustainable_investment": "Fastigheten är en hållbar investering",
	"EnergyClass": "Energiklass",
	"EnergyDeclarationID": "ID",
	"EnergyDeclarationIssuedDate": "Energideklaration utfärdat",
	"EnvironmentalImpact": "Miljöbelastning",
	"EnergyDeclarations": "Energideklarationer från Boverket",
	"Fetch": "Hämta",
	"EnergyDeclarationText1": "Den här datan hämtas från Boverkets API. Om ingen data hämtas har inte Boverket energideklarationer för fastigheten. För att datan ska kunna hämtas så måste Kommun för fastigheten vara satt.",
	"PrimaryEnergyNumber": "Primärenergital",
	"EnergyPerformance": "Spec. energianvändning",
	"YearOfConstruction": "Byggnadsår",
	"SpecificPropertyNameText": "Ange formell fastighetsbeteckning",
	"PropertyDesignation": "Fastighetsbeteckning",
	"BoverketErrorMessageSection1": "Det gick inte att hämta data från Boverket med kommunen",
	"BoverketErrorMessageSection2": "och fastighetsbeteckningen",
	"BoverketInfoMessageSection1": "Hämtar data från Boverket med kommunen",
	"BoverketInfoMessageSection2": "och fastighetsbeteckningen",
	"VentilationControl": "Ventilationskontroll",
	"RadonMeasurement": "Radonmätning",
	"InvalidContracts": "Antal kontrakt som ej går att uppdatera",
	"RecoveryShortcutTypesTurnover": "Omsättningstillägg",
	"EnvironmentalCertificates": "Miljöcert",
	"Certification": "Certifiering",
	"Level": "Nivå",
	"Moms": "Moms",
	"FirstYearSqm": "kr/m² år1",
	"Validity date": "Giltighetsdatum (tom)",
	"CalculationInterestStreamPercentage": "Kalkylränta, ström (%)",
	"CalculationRestValuePercentage": "Kalkylränta, restvärde (%)",
	"EstimatedMarketValueSqm": "Bedömt marknadsvärde, {currency}",
	"ValueChangeKr": "Värdeförändring,  {currency}",
	"ValueChangePercentage": "Värdeförändring (%)",
	"CompareVersion": "Jämfört med version:",
	"CurrentVersion": "Nuvarande version",
	"Maintenance":"Underhåll",
	"Administration":"Administration",
	"Sum":"Summa",
	"GreenDeal": "Grönt avtal",
	"NormalizedInvestmentDelta":"Varav värdeförändring investering, {currency}",
	"TenantImprovement": "Hyresgästanpassning",
	"ShowAmountColumn": "Antal-kolumn"
};