import { Component, Watch, Prop } from "vue-property-decorator";
import DataService from "@/services/data-service";
import BaseComponent from "@/components/base-component";
import { AddValuationRequest } from "@/models/interfaces/request/AddValuationRequest";
import { BusMessage, ReportAddedMessage } from '@/models/messages/messages';
import dataService from '@/services/data-service';
import { mapGetters } from 'vuex';
import TableSelectExtension from '@/helpers/table-select-extension';
import GeneratePortfolioComponent from '@/components/valuations/generate-portfolio/generate-portfolio';
import PortfolioValuationComponent from '@/components/valuations/portfolio-valuations/portfolio-valuations';
import { PortfolioValuationData } from '@/models/PortfolioValuationData';
import { SearchTab } from '@/constants/fia-constants';
import { Portfolio, PropertyVersion } from "@/models";

interface IDisplayValuationSearch {
    analysisId: number;
    calculationDate: string;
    calculationEnd: string;
    calculationStart: string;
    currencyId: number;
    fullName: string;
    propertyId: number;
    propertyIdentifier: string;
    propertyName: string;
    propertyVersionId: number;
    versionIdentifier: string;
}

interface IReports {
    id: number;
    name: string;
}

interface ModuleFilter {
    id: number,
    valuationInstanceId: number,
    moduleId: number,
    name: string,
    preSelected: boolean
}

@Component({
    components: {
        GeneratePortfolioComponent,
        PortfolioValuationComponent
    },
    computed: mapGetters({
        portfolio: "getPortfolio",
        propertyId: "getPropertyId",
        propertyVersion: "getPropertyVersion"
    })
})
export default class ValuationsComponent extends BaseComponent {
    portfolio!: Portfolio;
    propertyId!: number;
    propertyVersion!: PropertyVersion;

    propertyList: IDisplayValuationSearch[] = [];
    tempReportList: IDisplayValuationSearch[] = [];
    reports: IReports[] = [];
    selectedReport: number = 0;
    frontPage: boolean = false;
    forumLogo: boolean = false;
    searching: boolean = false;
    marketSituationFileBlob: File | null = null;
    moduleFilters: ModuleFilter[] = [];
    moduleFiltersSelected: ModuleFilter[] = [];
    select: TableSelectExtension = new TableSelectExtension(this, 'versionsTable', 'analysisId');
    generateReportLoading: boolean = false;
    portfolioValuationsVisible: boolean = false;
    lastPropertyId: number | null = null;

    @Prop()
    active!: string;

    @Prop()
    isPortfolio!: boolean;

    @Prop()
    activeSearchTab!: string;

    @Watch('active')
    async activeWatcher() {
        await this.reload();
    }

    @Watch('propertyId')
    async propertyIdWatcher() {
        await this.reload();
    }

    @Watch('portfolio')
    async portfolioWatcher() {
        await this.reload();
    }

    @Watch("selectedReport")
    onselect() {
        this.moduleFiltersSelected = [];
        this.moduleFilters.map(x => {
            if (x.preSelected && x.valuationInstanceId === this.selectedReport)
                this.moduleFiltersSelected.push(x);
        })
    }

    @Watch("propertyVersion")
    async propertyVersionWatcher() {
        if (this.isPortfolio) return;
        
        if (this.propertyVersion && this.lastPropertyId !== this.propertyVersion.propertyId) {
            await this.reload();
        } else {
            this.selectCurrentVersion();
        }
        this.lastPropertyId = this.propertyVersion ? this.propertyVersion.propertyId : null;
    }

    async mounted() {
        try {
            this.reports = await DataService.getValuationInstances().then((x: any) => x.data);
        } catch (error) {
        }

        if (this.reports.length > 0)
            this.selectedReport = this.reports[0].id;

        try {
            this.moduleFilters = await dataService.getModuleFilters().then(x => x.data);
            this.moduleFilters.map(x => {
                if (x.preSelected && x.valuationInstanceId === this.selectedReport)
                    this.moduleFiltersSelected.push(x);
            })
        } catch (error) {
        }
    }
    
    async reload() {
        if (this.active === 'valuations' &&
            (this.activeSearchTab === SearchTab.PortfolioSearch && this.isPortfolio ||
             this.activeSearchTab === SearchTab.PropertySearch && !this.isPortfolio)) {
            await this.search();
            this.selectCurrentVersion();
        }
    }

    selectCurrentVersion() {
        if (this.propertyVersion) {
            const listItem = this.propertyList.find(x => x.propertyVersionId === this.propertyVersion.id)
            if (listItem) {
                this.handleSelection([listItem], listItem);
            }
        }
    }

    async search() {
        this.tempReportList = []

        if (this.isPortfolio && !this.portfolio || !this.isPortfolio && this.propertyId === 0) {
            this.propertyList = [];
            return;
        }

        try {
            this.searching = true;
            const id = this.isPortfolio ? this.portfolio.id : this.propertyId;
            this.propertyList = await DataService.searchValuations(id, this.isPortfolio).then(x => x.data);
        } catch (error) {
            return Promise.reject(new Error(""));
        } finally {
            this.searching = false;
        }
    }

    currencyTableFormat(row: any, column: any, cellValue: any) {
        return this.translateBaseData(cellValue, this.baseDataType.Currencies);
    }

    handleSelection(items: IDisplayValuationSearch[], item: IDisplayValuationSearch) {
        this.select.select(items, item);
        this.tempReportList = items ? items : [];
    }

    noneSelected() {
        if (this.tempReportList.length === 0)
            return true;
        else
            return false;
    }

    async generate(portfolioValuationData: PortfolioValuationData | null = null) {
        this.generateReportLoading = true;

        let request: AddValuationRequest = {
            valuationInstanceId: this.selectedReport,
            frontpage: this.frontPage,
            forumLogo: this.forumLogo,
            show: true,
            marketSituationFileBlob: null,
            filteredModuleIds: this.moduleFiltersSelected.map(x => x.moduleId).join(','),
            analysisId: 0, // Set in iteration below
            identityId: '', // Set in iteration below
            portfolioValuationData: portfolioValuationData,
        }

        let addedCounter = 0;
        for (let i of this.tempReportList) {
            var data = new FormData();
            request.analysisId = i.analysisId;
            request.identityId = i.propertyVersionId.toString();

            Object.keys(request).forEach(key => {
                let obj = (<any>request)[key];
                if (obj == null) return;
                if (typeof obj === 'object' && Object.keys(obj).length > 0) {
                    Object.keys(obj).forEach(oKey => {
                        data.append(key + "." + oKey, obj[oKey]);
                    });
                    return;
                }
                data.append(key, (<any>request)[key]);
            });
            if (this.marketSituationFileBlob) {
                data.append("marketSituationFileBlob", this.marketSituationFileBlob);
            }

            try {
                await DataService.addValuation(data);
                addedCounter++;
            } catch (error) {
            }
        }

        if (addedCounter > 0) {
            this.$store.dispatch("sendBusMessage", <BusMessage<ReportAddedMessage>>{ type: "ReportAdded" });
        } else {
            return Promise.reject(new Error(""));
        }
        this.generateReportLoading = false;
    }

    url() {
        return "";
    }

    onError(error: any) {
        this.$notify.error({
            title: this.translate("HttpRequestError"),
            message: this.translate(error.message)
        });
    }

    onChange(file: any, fileList: any[]) {
        if (fileList.length > 1)
            fileList.shift();
        this.marketSituationFileBlob = file.raw;
    }

    onRemove(file: any, fileList: any) {
        this.marketSituationFileBlob = null;
    }

    async setUploadData(file: any): Promise<any> {
        return new Promise(resolve => {
            resolve(file);
        });
    }
}