import { DateFormat, PaymentStreamType, TypeOfPaymentEnum } from "@/constants/fia-constants";
import { PaymentStream, Contract, IndexIncrementStream, PercentageIncrementStream } from "@/models";
import moment from "moment";

export default new class Utils {
    isPercentageIncrementStream(stream: PaymentStream | null): boolean {
        if (!stream) return false;

        return stream.typeOfPaymentStreamId == PaymentStreamType.BasicPercentageStream ||
            stream.typeOfPaymentStreamId == PaymentStreamType.IndexIncrementStream ||
            stream.typeOfPaymentStreamId == PaymentStreamType.AdvancedPercentageStream ||
            stream.typeOfPaymentStreamId == PaymentStreamType.AdvancedPercentageStreamAdvancedGui;
    }

    isPercentageIncrementStreamType(type: PaymentStreamType): boolean {
        return type == PaymentStreamType.BasicPercentageStream ||
            type == PaymentStreamType.IndexIncrementStream ||
            type == PaymentStreamType.AdvancedPercentageStream ||
            type == PaymentStreamType.AdvancedPercentageStreamAdvancedGui;
    }

    getSortedStreams(streams: PaymentStream[]): PaymentStream[] {
        return streams.slice().sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
    }

    getFirstStream(streams: PaymentStream[]): PaymentStream {
        let sortedStreams = this.getSortedStreams(streams);
        return sortedStreams[0];
    }

    getLastStream(streams: PaymentStream[]): PaymentStream {
        let sortedStreams = this.getSortedStreams(streams);
        return sortedStreams[sortedStreams.length - 1];
    }

    updateContractEndDate(contract: Contract): void {
        // Prolonging stream (last stream)
        let lastStream = this.getLastStream(contract.paymentStreams);
        if (lastStream) {
            lastStream.endDate = contract.endDate;
        }

        // Recoveries & streams
        let recoveries = contract.recoveries.filter(x => x.costIncome.transaction.typeOfPaymentId !== TypeOfPaymentEnum.RentDiscounts &&
                                                         x.costIncome.transaction.typeOfPaymentId !== TypeOfPaymentEnum.AdditionForConversion);
        for (let rec of recoveries) {
            rec.endDate = contract.endDate;

            let lastRecStream = this.getLastStream(rec.costIncome.paymentStreams);
            if (lastRecStream.endDate != null) {
                lastRecStream.endDate = contract.endDate;
            }
        }
    }

    updateContractStartDate(contract: Contract, updateFirstIncrement: boolean): void {
        // Prolonging stream (last stream)
        let firstStream = this.getFirstStream(contract.paymentStreams);
        if (firstStream) {
            firstStream.startDate = contract.startDate;
        }

        // Recoveries & streams
        let recoveries = contract.recoveries.filter(x => x.costIncome.transaction.typeOfPaymentId !== TypeOfPaymentEnum.RentDiscounts &&
                                                         x.costIncome.transaction.typeOfPaymentId !== TypeOfPaymentEnum.AdditionForConversion);
        for (let rec of recoveries) {
            rec.startDate = contract.startDate;

            let firstRecStream = this.getFirstStream(rec.costIncome.paymentStreams);
            if (firstRecStream.startDate != null) {
                firstRecStream.startDate = contract.startDate;
            }
        }

        if (updateFirstIncrement) {
            if ([PaymentStreamType.BasicPercentageStream, PaymentStreamType.AdvancedPercentageStream, PaymentStreamType.IndexIncrementStream].includes(firstStream.typeOfPaymentStreamId)) {
            // && new Date((firstStream as PercentageIncrementStream).firstIncrement) <= new Date(contract.startDate)) 
                (firstStream as PercentageIncrementStream).firstIncrement = moment(contract.startDate).add(1, "year").startOf("year").format(DateFormat);
            }
        }
    }

    getObjectProperty(path: string, obj: any) {
        const properties = path.split('.');
        let property = obj;
    
        for (let i = 0; i < properties.length - 1; i++) {
            property = property[properties[i]];
        }
    
        return property[properties[properties.length - 1]];
    }

    setObjectProperty(path: string, value: any, obj: any) {
        const properties = path.split('.');
        let property = obj;
    
        for (let i = 0; i < properties.length - 1; i++) {
            property = property[properties[i]];
        }
    
        property[properties[properties.length - 1]] = value;
    }

    yearMonthEqual(date1: string | null, date2: string | null) {
        if (!date1 && !date2) {
            return true;
        } else if (!date1 || !date2) {
            return false;
        }
        const mDate1 = moment(date1);
        const mDate2 = moment(date2);
        return mDate1.year() == mDate2.year() && mDate1.month() == mDate2.month();
    }

    isValidDate(dateString: string) {
        return moment(dateString).isValid();
    }

    int(s: string) {
        return parseInt(s?.replaceAll(/\s+/g, '').replaceAll(',','.'));
    }

    date(s: string, endOfMonth: boolean) {
        return endOfMonth ? moment(s).endOf('month').startOf('day').format(DateFormat) : moment(s).startOf('month').format(DateFormat);
    }
}