import { AxiosPromise, AxiosResponse } from 'axios';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import BaseComponent from '@/components/base-component';
import InputNumericComponent from '@/components/common/input-numeric/input-numeric';
import InputTextComponent from '@/components/common/input-text/input-text';
import ValidationErrorComponent from '@/components/common/validation-error/validation-error';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import ConctractRecoveriesEditComponent from '@/components/contract/contract-recoveries-edit/contract-recoveries-edit';
import { CalculationState } from '@/models/interfaces/CalculationState';
import {
    BaseDataType, FiaServerApi$type, monthList, NewStreamLogic, paymentFrequencies,
    PaymentStreamType, TypeOfPaymentEnum
} from '@/constants/fia-constants';
import InlineEdit from '@/helpers/inline-edit';
import {
    AdvancedPercentageIncrementStream, Contract, CostIncome, PaymentStream, PropertyVersion,
    Recovery, ShareOfPaymentStream, Transaction, TypeOfPayment, User, VariableAmount, VariableAmountIncrementStream
} from '@/models';
import { ValidationError } from '@/models/interfaces/ValidationError';
import { ValidationRule } from "@/models/interfaces/ValidationRule";

@Component({
    components: {
        YearMonthPickerComponent,
        InputNumericComponent,
        ConctractRecoveriesEditComponent,
        InputTextComponent,
        ValidationErrorComponent
    },
    computed: mapGetters({
        propertyVersion: "getPropertyVersion",
        user: "getUser",
    })
})
export default class ContractTenantImprovementComponent extends BaseComponent {
    propertyVersion!: PropertyVersion;
    user!: User;

    tenantImprovementGrid: InlineEdit<Recovery>;
    dialogEditRecovery: Recovery | null = null;
    dialogVisible: boolean = false;
    saving: boolean = false;
    monthList: number[] = monthList;
    frequencyList = paymentFrequencies;
    typeOfPaymentList: TypeOfPayment[] = [];
    shortcutTypes: TypeOfPaymentEnum[] = [
        TypeOfPaymentEnum.TenantImprovement,
    ];

    constructor() {
        super();
        this.tenantImprovementGrid = new InlineEdit<Recovery>(this, "tenantImprovementTable", this.loadRecoveries, undefined, undefined, this.newTenantImprovement, undefined, this.hasNoErrors);
        this.tenantImprovementGrid.sync = true;
        this.tenantImprovementGrid.readOnly = this.versionLocked();
        this.tenantImprovementGrid.useConfirmDeleteDialog = false;
    }

    @Prop()
    contract!: Contract;

    @Prop()
    complexErrors!: ValidationError[];

    @Watch("contract", { immediate: true })
    contractWatcher() {
        this.tenantImprovementGrid.reload();
    }

    @Watch("tenantImprovementGrid.editItem")
    currentRecoveryWatcher() {
        if (this.tenantImprovementGrid.editItem) {
            let typeOfPaymentId = this.tenantImprovementGrid.editItem.entity.costIncome.transaction.typeOfPaymentId
            this.typeOfPaymentList = this.$store.getters.getActiveTypeOfPayments([typeOfPaymentId]);
        }
    }

    @Prop()
    reload!: boolean;

    @Watch("reload")
    reloadWatcher() {
        if (this.reload == true) {
            (<any> this).$refs.tenantImprovementTable.doLayout();
        }
    }

    loadRecoveries(): AxiosPromise<Recovery[]> {
        return Promise.resolve(<AxiosResponse>{ data: this.contract ? this.contract.recoveries : [] });
    }

    filteredRecoveries() {
        return this.tenantImprovementGrid.items.filter(recovery =>
          recovery.entity.costIncome && recovery.entity.costIncome.transaction &&
          recovery.entity.costIncome.transaction.typeOfPaymentId === TypeOfPaymentEnum.TenantImprovement
        );
      }

    edit(recovery: Recovery) {
        const item = this.tenantImprovementGrid.items.find(x => x.entity.id === recovery.id);
        if (item) {
            this.tenantImprovementGrid.itemSelected(item);
            this.dialogEditRecovery = recovery;
            this.dialogVisible = true;
        }
    }

    checkValidForEdit(): boolean {
        if (this.complexErrors.some(x => x.rule !== ValidationRule.Warning)) {
            this.$message({ message: this.translate("ErrorMessage_RecoveryEditInvalidContract"), type: "error" });
            return false;
        }
        return true;
    }

    async addTenantImprovement() {
        if (!this.checkValidForEdit()) return;
        await this.tenantImprovementGrid.add();
        if (this.tenantImprovementGrid.currentItem) {
            this.edit(this.tenantImprovementGrid.currentItem.entity);
        }
    }

    async handleCommand(shortcut: TypeOfPaymentEnum) {
        let currentDate = new Date();
        let newRecovery: Recovery;
        let startDateTemp = this.propertyVersion.calculationValue?.startDate ? new Date(this.propertyVersion.calculationStart) : new Date(this.contract.startDate); 
        let year = startDateTemp.getFullYear();
        let startDate = this.monthStart(startDateTemp);
        let endDate = this.monthStart(new Date(year, 11, 31));
        switch (shortcut) {
            case TypeOfPaymentEnum.TenantImprovement:
                newRecovery = <Recovery>{
                    id: this.getNewId(this.tenantImprovementGrid.entities),
                    contractId: this.contract.id,
                    startDate: startDate,
                    endDate: endDate,
                    costIncome: <CostIncome> {
                        id: 0,
                        description: this.translate("TenantImprovement"),
                        recoveryId: 0,
                        transaction: <Transaction> {
                            id: 0,
                            propertyVersionId: this.propertyVersion.id,
                            comment: "",
                            fromSink: false,
                            changedDate: this.monthStart(currentDate),
                            typeOfPaymentId: TypeOfPaymentEnum.TenantImprovement,
                        },
                        paymentStreams: [<VariableAmountIncrementStream> {
                            $type: FiaServerApi$type.VariableAmountIncrementStreamDto,
                            comment: "",
                            endDate: endDate,
                            id: 0,
                            inAdvance: true,
                            maintenanceCost: null,
                            margin: 0,
                            paymentFrequency: 3,
                            prolongingId: 0,
                            startDate: startDate,
                            typeOfPaymentStreamId: PaymentStreamType.VariableAmountIncrementStream,
                            variableAmounts: [<VariableAmount> {
                                id: 0,
                                amount: 0,
                                startDate: startDate
                            }]
                        }]
                    }
                };;
                break;  
            default:
                throw "Command type not supported";
        }
        this.contract.recoveries.push(newRecovery);
        await this.tenantImprovementGrid.reload();
        this.tenantImprovementGrid.itemSelected(this.tenantImprovementGrid.items[this.tenantImprovementGrid.items.length - 1]);
    }

    getContractStreamFirstIncrementDate() {
        let firstIncrement = new Date(this.propertyVersion.calculationStart);
        if (firstIncrement < new Date(this.contract!.startDate)) {
            firstIncrement = new Date(this.contract!.startDate);
        }

        switch (firstIncrement.getMonth()) {
            case 0:
                firstIncrement = new Date(firstIncrement.getFullYear(), 0, 1);
            default:
                firstIncrement = new Date(firstIncrement.getFullYear() + 1, 0, 1);
        }

        return this.monthStart(firstIncrement);
    }

    translateCommand(value: TypeOfPaymentEnum) {
        var base: BaseDataType = BaseDataType.TypeOfPayments;
        var trans = this.translateBaseData(value, base);
        return trans;
    }

    async closed(canceled: boolean) {
        if (canceled) {
            await this.tenantImprovementGrid.reload();
        }
    }

    newTenantImprovement(): Recovery {
        let currentDate = new Date();
        let startDateTemp = this.propertyVersion.calculationValue?.startDate ? new Date(this.propertyVersion.calculationStart) : new Date(this.contract.startDate); 
        let year = startDateTemp.getFullYear();
        let startDate = this.monthStart(startDateTemp);
        let endDate = this.monthStart(new Date(year, 11, 31));
        return <Recovery>{
            id: this.getNewId(this.tenantImprovementGrid.entities),
            contractId: this.contract.id,
            startDate: startDate,
            endDate: endDate,
            costIncome: <CostIncome> {
                id: 0,
                description: this.translate("TenantImprovement"),
                recoveryId: 0,
                transaction: <Transaction> {
                    id: 0,
                    propertyVersionId: this.propertyVersion.id,
                    comment: "",
                    fromSink: false,
                    changedDate: this.monthStart(currentDate),
                    typeOfPaymentId: TypeOfPaymentEnum.TenantImprovement,
                },
                paymentStreams: [<VariableAmountIncrementStream> {
                    $type: FiaServerApi$type.VariableAmountIncrementStreamDto,
                    comment: "",
                    endDate: endDate,
                    id: 0,
                    inAdvance: true,
                    maintenanceCost: null,
                    margin: 0,
                    paymentFrequency: 3,
                    prolongingId: 0,
                    startDate: startDate,
                    typeOfPaymentStreamId: PaymentStreamType.VariableAmountIncrementStream,
                    variableAmounts: [<VariableAmount> {
                        id: 0,
                        amount: 0,
                        startDate: startDate
                    }]
                }]
            }
        };
    }

    closeDialog() {
        this.dialogVisible = false;
    }

    saved(contract: Contract) {
        this.$emit("saved", contract);
    }

    formatBooleanRecoveries(value: any): string {
        return this.translate(value ? "No" : "Yes");
    }

    calcEndChanged(value: boolean, firstStream: PaymentStream) {
        firstStream.endDate = value ? null : this.monthEnd(this.contract.endDate);
    }

    startDateChanged(recovery: Recovery) {
        recovery.startDate = this.monthStart(recovery.costIncome.paymentStreams[0].startDate);
    }

    endDateChanged(recovery: Recovery) {
        if (recovery.costIncome.paymentStreams.length === 1) {  // TODO: check this!
            let firstStream = recovery.costIncome.paymentStreams[0];
            recovery.endDate = firstStream.endDate ? firstStream.endDate : this.monthEnd(this.propertyVersion.calculationEnd);
        }
    }

    canAddRecovery(): boolean {
        return !this.versionLocked();
    }

    getRecoveryErrors(recovery: Recovery) : ValidationError[] {
        return this.complexErrors.filter(e => e.refId === recovery.id || recovery.costIncome.paymentStreams.some(ps => ps.id.toString() === e.refId))
    }
    
    hasStreams(recovery: Recovery): boolean {
        return recovery.costIncome.paymentStreams && recovery.costIncome.paymentStreams.length > 0;
    }
}