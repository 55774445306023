import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import YearMonthPickerComponent from '@/components/common/year-month-picker/year-month-picker';
import dataService from '@/services/data-service';
import { ContractListItem } from '@/models/interfaces/ContractListItem';
import { UpdateContractDateRequest } from '@/models/interfaces/request/UpdateContractDateRequest';
import { PremisesType, Recovery, TypeOfPayment } from '@/models';
import { mapGetters } from 'vuex';
import { TypeOfPaymentEnum, ProlongingType } from '@/constants/fia-constants';
import { UpdateContractRecoveryTaxTypeRequest } from '@/models/interfaces/request/UpdateContractRecoveryTaxTypeRequest';
import { ContractValidationResponse } from '@/models/interfaces/response/ContractValidationResponse';
import { UpdateContractProlongingTypeRequest } from '@/models/interfaces/request/UpdateContractProlongingTypeRequest';
import { UpdateContractTypeOfPaymentRequest } from '@/models/interfaces/request/UpdateContractTypeOfPaymentRequest';
import { UpdateContractVatRequest } from '@/models/interfaces/request/UpdateContractVatRequest';
import { UpdateContractMainPremisesTypeRequest } from '@/models/interfaces/request/UpdateContractMainPremisesTypeRequest';
import { UpdateContractGreenDealRequest } from '@/models/interfaces/request/UpdateContractGreenDealRequest';
import ContractRecoveriesAdjustmentComponent from '@/components/contract/contract-recoveries-edit/contract-recoveries-adjustment/contract-recoveries-adjustment';

enum Actions {
    FirstIncrementRecoveries,
    FirstIncrementContracts,
    ContractStart,
    ContractEnd,
    TaxRecovery,
    ProlongingType,
    PaymentType,
    Vat,
    MainPremisesType,
    RecoveriesAdjustment,
    GreenDeal
}

@Component({
    components: {
        YearMonthPickerComponent,
        ContractRecoveriesAdjustmentComponent: ContractRecoveriesAdjustmentComponent
    },
    computed: mapGetters({
        typeOfPaymentList: "getTypeOfPayments",
        premisesTypesList: "getPremisesTypes"
    })
})
export default class ContractUpdateSelectedComponent extends BaseComponent {
    @Prop()
    open!: boolean;

    @Prop()
    contracts!: ContractListItem[];

    typeOfPaymentList!: TypeOfPayment[];
    premisesTypesList!: PremisesType[];
    propertyTaxList: TypeOfPayment[] = [];

    selectedContracts: ContractListItem[] = [];
    invalidContracts: ContractValidationResponse[] = [];
    recoveries: Recovery[] = [];
    visible: boolean = false;
    action: Actions = Actions.RecoveriesAdjustment;
    actions = Actions;
    loading: boolean = false;

    selectedPayment: number = TypeOfPaymentEnum.PropertyTaxFacilities;
    selectedVat: number | null = null;
    selectedGreenDeal: number | null = null;
    selectedProlongingType: number = ProlongingType.UseMarketRent;
    date: string = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toDateString();
    firstIncrementRecoveries: string = "";
    firstIncrementContract: string = "";
    selectedMainPremisesType: number | null = null;

    @Watch("open")
    openWatcher() {
        if (this.open) {
            this.invalidContracts = [];
            this.selectedContracts = [];
            this.selectedContracts = this.contracts.slice();
            this.visible = true;
            this.date = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toDateString();
            this.loading = false;
            if (this.$refs.recoveryAdjustmentComponent) {
                (<ContractRecoveriesAdjustmentComponent>this.$refs.recoveryAdjustmentComponent).load();
            }
        }
    }

    mounted() {
        this.propertyTaxList = this.typeOfPaymentList.filter(x => x.id == TypeOfPaymentEnum.PropertyTax || x.id == TypeOfPaymentEnum.PropertyTaxFacilities || x.id == TypeOfPaymentEnum.PropertyTaxIndustrial || x.id == TypeOfPaymentEnum.PropertyTaxResidential);
    }

    cancel() {
        this.close(true);
    }

    close(canceled: boolean = false) {
        if (this.$refs.recoveryAdjustmentComponent) {
            (<ContractRecoveriesAdjustmentComponent>this.$refs.recoveryAdjustmentComponent).clear();
        }
        this.invalidContracts = [];
        this.selectedContracts = [];
        this.$emit("update:open", false);
        this.$emit("closed", canceled);
    }

    async ok() {
        this.invalidContracts = [];
        let request;
        try {
            this.loading = true;
            if (this.action == Actions.TaxRecovery) {
                request = <UpdateContractRecoveryTaxTypeRequest>{ contractIds: this.selectedContracts.map(x => x.id), taxTypeId: this.selectedPayment };
                await dataService.updateContractRecoveryTaxType(request);
            } else if (this.action == Actions.ProlongingType) {
                request = <UpdateContractProlongingTypeRequest>{ contractIds: this.selectedContracts.map(x => x.id), prolongingTypeId: this.selectedProlongingType };
                await dataService.updateContractProlongingType(request);
            }
            else if (this.action == Actions.PaymentType) {
                request = <UpdateContractTypeOfPaymentRequest>{ contractIds: this.selectedContracts.map(x => x.id), typeOfPaymentId: this.selectedPayment };
                await dataService.updateContractTypeOfPayment(request);
            }
            else if (this.action == Actions.Vat) {
                request = <UpdateContractVatRequest>{ contractIds: this.selectedContracts.map(x => x.id), vat: this.selectedVat };
                await dataService.updateContractVat(request);
            }
            else if (this.action == Actions.GreenDeal) {
                request = <UpdateContractGreenDealRequest>{ contractIds: this.selectedContracts.map(x => x.id), greenDeal: this.selectedGreenDeal };
                await dataService.updateContractGreenDeal(request);
            }
            else if (this.action == Actions.MainPremisesType) {
                request = <UpdateContractMainPremisesTypeRequest>{ contractIds: this.selectedContracts.map(x => x.id), mainPremisesTypeId: this.selectedMainPremisesType };
                await dataService.updateContractMainPremisesType(request);
            }
            else if (this.action == Actions.RecoveriesAdjustment) {
                await (<ContractRecoveriesAdjustmentComponent>this.$refs.recoveryAdjustmentComponent).save();
            }
            else {
                request = <UpdateContractDateRequest>{ contractIds: this.selectedContracts.map(x => x.id), date: this.date };
                switch (this.action) {
                    case this.actions.FirstIncrementRecoveries:
                        await dataService.updateRecoveriesFirstIncrement(request);
                        break;
                    case this.actions.FirstIncrementContracts:
                        await dataService.updateContractFirstIncrement(request);
                        break;
                    case this.actions.ContractStart:
                        await dataService.updateContractStart(request);
                        break;
                    case this.actions.ContractEnd:
                        await dataService.updateContractEnd(request);
                        break;
                }
            }
            this.loading = false;
            this.close();
        } catch (error: any) {
            this.invalidContracts = error.response.data;
            this.loading = false;
            return Promise.reject(new Error(""));
        }
    }

    async calculate() {
        this.invalidContracts = [];
        try {
            if (this.action == Actions.RecoveriesAdjustment) {
                await (<ContractRecoveriesAdjustmentComponent>this.$refs.recoveryAdjustmentComponent).calculate();
            }
        } catch (error: any) {
            this.invalidContracts = error.response.data;
            return Promise.reject(new Error(""));
        }
    }

    valid(): boolean {
        return this.date !== '';
    }

    getDateLabel(): string {
        switch (this.action) {
            case Actions.ContractEnd:
                return this.translate('EndDate')
            case Actions.ContractStart:
                return this.translate('StartDate');
            case Actions.FirstIncrementContracts:
                return this.translate('FirstIncrementMonth_Long');
            case Actions.FirstIncrementRecoveries:
                return this.translate('FirstIncrementMonth_Long');
        }
        return "";
    }

    validationFeedback(arg: { row: ContractListItem, rowIndex: number }) {
        let row: ContractListItem = arg.row;
        if (Array.isArray(this.invalidContracts) && this.invalidContracts.map(x => x.contractId).includes(row.id)) {
            return "row-validation-error";
        }
        return "";
    }

    getValidationErrors(id: number): string {
        if (this.invalidContracts == null) return "";
        let index = this.invalidContracts.map(x => x.contractId).indexOf(id);
        if (this.invalidContracts.length > 0 && index != -1) {
            return this.invalidContracts[index].error.join("\r\n");
        }
        return "";
    }

    remove(index: number) {
        let c = this.selectedContracts[index];
        if (this.invalidContracts != null && this.invalidContracts.map(x => x.contractId).includes(c.id)) {
            let i = this.invalidContracts.map(x => x.contractId).indexOf(c.id);
            this.invalidContracts.splice(i, 1);
        }

        this.selectedContracts.splice(index, 1);
    }

    onChangeAction() {
        this.date = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toDateString();

        switch (this.action) {
            case this.actions.TaxRecovery: this.selectedPayment = TypeOfPaymentEnum.PropertyTaxFacilities; break;
            case this.actions.PaymentType: this.selectedPayment = TypeOfPaymentEnum.RentOffice; break;
        }
    }

    largeDialog() {
        return this.action == this.actions.RecoveriesAdjustment;
    }

    getOkButtonText(): string {
        return this.action == Actions.RecoveriesAdjustment ? "SaveAndCalculate" : "Ok";
    }

}