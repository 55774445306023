import { DateFormat } from '@/constants/fia-constants';
import { ValidationError } from '@/models/interfaces/ValidationError';
import moment from 'moment';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';


@Component({})
export default class InputDateComponent extends Vue {
    @Prop()
    value!: any;
    @Prop()
    dateFormat!: string;
    @Prop({default: false})
    endOfMonth!: boolean;
    @Prop({ default: () => [], required: false })
    complexError!: ValidationError[];
    @Prop({ default: false })
    disabled!: boolean;
    @Prop({ default: false })
    nullable!: boolean;

    propertyString: string = "";
    valid = true;

    @Watch('value')
    valueWatcher() {
        this.propertyString = this.value;
        this.format();
    }

    mounted() {
        this.propertyString = this.value;
        this.format();
    }

    format() {
        if ((!this.value || this.value.length == 0) && this.nullable) {
            return;
        }

        this.valid = moment(this.propertyString).isValid();
        this.propertyString = moment(this.propertyString).format(this.dateFormat);
        let date = moment(this.propertyString).startOf('month').format(DateFormat);
        if (this.endOfMonth) {
            date = moment(this.propertyString).endOf("month").startOf("day").format(DateFormat);
        }
        this.$emit("input", date);
    }

    change(date: any) {
        this.format();
        this.$emit("change", date);
    }

    focus(event: any) {
        this.$emit("focus", event);
    }

    errorText(): string {
        return this.hasErrors() ? this.complexError.map(x => "- " + x.error).join('\r\n') : "";
    }

    hasErrors() {
        return !this.valid || this.complexError.length > 0;;
    }
}
