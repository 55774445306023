import { Component, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from 'vuex'
import { Currency } from "@/models";
import BaseComponent from '@/components/base-component';
import Vue from 'vue';
import { TreeGridPlugin, TreeGridComponent, Sort, Aggregate, TreeGrid, Toolbar, Resize, AggregateColumn, Column } from '@syncfusion/ej2-vue-treegrid';
import { BaseDataType, BaseDataStatus } from '@/constants/fia-constants';
import { Tenant } from '@/models/interfaces/web-report/Tenant';
import { isNumeric } from "@/helpers/numbers";
import { CurrencyHelper } from "@/helpers/currency";
import moment from "moment";

Vue.use(TreeGridPlugin);
TreeGrid.Inject(Resize, Toolbar, Sort, Aggregate);

export enum CurrencyUnit {
    Unit, Thousands, Sqm, PerSqm, Percentage
}

@Component({
    components: {

    },
    computed: mapGetters({})
})
export default class RentCompositionComponent extends BaseComponent {
    
    loading: boolean = false;
    currencyUnit = CurrencyUnit;
    languageId: number = 0;

    @Prop()
    rentCompositionData!: Tenant[];
    @Prop()
    currency!: Currency | null | undefined;
    @Prop()
    currencyRate!: number;
    @Prop({default: true})
    isVersion!: boolean;

    async refresh() {
        let grid = (<TreeGridComponent> this.$refs.rentCompositionGrid);
        if (grid) {
            grid.refresh();
        } else {
            console.warn('$refs.rentCompositionGrid is undefined');
        }
    }

    mounted() {
        this.languageId = this.$store.getters.getUser.languageId;
    }

    gridDataBound(gridName: any) {
        
    }

    rowDataBound() {
        
    }

    gridToolbarClick(args: any) {
        switch (args.item.id) {
            case 'excel': (<any>this.$refs.rentCompositionGrid).excelExport(); break;
        }
    }

    summaryRow() {
        return { template : Vue.component('maxTemplate', {
            template: `<span>Max: </span>`
        })};
    }

    tableFormatter(col: Column, data: any) {
        const split = col.field.split('.');
        var field = split[split.length-1];
        var value = split.reduce((a, b) => a[b], data);
        if(col.field=="area" && value == 0) //Overridden by design, we want to show '0' values for area.
            return 0;
        return this.tableFormat(field, value, false);
    }

    tableFormatterAggregate(col: AggregateColumn, data: any) {
        const split = col.columnName.split('.');
        const field = split[split.length-1];
        const value = data[col.columnName].Sum;
        const formattedValue = this.tableFormat(field, value, true);
        return formattedValue ? formattedValue : 0;
    }

    tableFormatterTenant(col: Column, data: any) {
        var value = data.tenant;
        if(value === "Fastighetsskatt" && this.languageId === 2){
             value = "Property tax";
        } else if (value === "Totalt inkl. skattetillägg" && this.languageId === 2){
             value = "Total incl. tax recoveries";
        }
        return value;
    }

    tableFormatterStartDate(col: Column, data: any){
        var statusId = data.statusId;
        if(statusId === BaseDataStatus.Vacant || statusId === BaseDataStatus.VacantRefurbishmentsTakingPlace || 
            statusId === BaseDataStatus.VacantLongTerm || statusId === BaseDataStatus.VacantLongerTerm){
             return this.formatYearMonth(moment(data.endDate).add(1, "month"));
         } else {
            return this.tableFormatter(col, data);

         }
    }

    tableFormatterEndDate(col: Column, data: any){
        var statusId = data.statusId;
        if(statusId === BaseDataStatus.Vacant || statusId === BaseDataStatus.VacantRefurbishmentsTakingPlace || 
            statusId === BaseDataStatus.VacantLongTerm || statusId === BaseDataStatus.VacantLongerTerm){
             return '';
         } else {
            return this.tableFormatter(col, data)
         }
    }


    tableFormat(field: string, value: any, isAggregateCol: boolean = false) {
        if (!value && field != "moms") {
            return null;
        } else if(value == "Infinity"){
            return null;
        }

        switch (field) {
            case "baseRent":
            case "rentExRecoveries":
            case "rentExRecoveriesSqm":
            case "rent":
            case "tax":
            case "marketRent":
            case "heat":
            case "misc":
            case "turnover":
            case "discount":
            case "tenant":
            case "conversion":
                value *= this.currencyRate;
                value = this.formatNumber(this.decimals(value * 0.001, 0), 0); break;
            case "baseNumber":
                value = this.formatNumber(this.decimals(value, 2), 0); break;
            case "rentSqm":
            case "marketRentSqm":
                value *= isAggregateCol ? 1 : this.currencyRate;
                value = this.formatNumber(this.decimals(value, 0), 0); break;
            case "area":
                value = this.formatNumber(this.decimals(value, 0), 0); break;
            case "startDate":
            case "endDate":
                value = this.formatYearMonth(value); break;
            case "indexPercentage":
                value = this.formatPercentage(value, 0, false); break;
            case "premisesTypeId":
                value = this.translateBaseData(value, BaseDataType.PremisesTypes); break;
            case "moms":
            case "greenDeal":
                value = this.formatBoolean(value)
            default: value = value; break;
        }

        return value;
    }

    sqmFormatter(col: AggregateColumn, data: any) {
        let area = this.sum(this.rentCompositionData.map(x => x.area));
        let value = null;
        switch (col.field) {
            case "rentSqm":
                value = this.sum(this.rentCompositionData.map(x => x.rent)) * this.currencyRate / area;
                break;
            case "marketRentSqm":
                value = this.sum(this.rentCompositionData.map(x => x.marketRent)) * this.currencyRate / area;
                break;
            case "rentExRecoveriesSqm":
                value = this.sum(this.rentCompositionData.map(x => x.rentExRecoveries)) * this.currencyRate / area;
                break;    
        }
        
        return value && isFinite(value) ? this.formatNumber(this.decimals(value, 0), 0) : null;
    }

    formatCurrency(currencyUnit: CurrencyUnit) {
        return CurrencyHelper.formatCurrency(this.currency, currencyUnit);
    }

    sum(map: any) {
        return map.reduce((a: number, b: number) => a + b, 0);
    }
}
